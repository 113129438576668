import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import useUserMaintenance from '../../../context';
import FieldRenderer from '../../FieldRenderer';
import translator from '../../../../../../services/translator';
import { ADD, CHANGE, DELETE } from '../../../constants';
import { VALIDATE_INPUT, UPDATE_SSO_ENTITLEMENTS_FIELD, UPDATE_PAGE_EDITED } from '../../../actions';
import './index.scss';

const { translate: t } = translator;

export default function SsoEntitlementsGroup({ data, testId }) {
  const {
    state: { isEditable, userDetailsValidation, userDetails: { ssoClient = [] } = {}, isPageEdited },
    dispatch,
  } = useUserMaintenance();

  const handleChange = useCallback(({ event, clientName, errorMessage, alias, fieldIndex }) => {
    if (!isPageEdited) dispatch({ type: UPDATE_PAGE_EDITED, payload: { isPageEdited: true } });

    switch (event) {
      case ADD:
        dispatch({ type: UPDATE_SSO_ENTITLEMENTS_FIELD, payload: { action: ADD } });
        break;
      case CHANGE:
        dispatch({ type: UPDATE_SSO_ENTITLEMENTS_FIELD, payload: { action: CHANGE, fieldIndex, clientName, alias } });
        break;
      case DELETE:
        dispatch({ type: UPDATE_SSO_ENTITLEMENTS_FIELD, payload: { action: DELETE, fieldIndex } });
        break;
      default:
        if (errorMessage) {
          dispatch({ type: VALIDATE_INPUT, payload: { fieldName: clientName, isValid: !errorMessage, errorMessage } });
        }
    }
  }, []);

  const getSSOValue = useCallback(
    value => {
      return (
        ssoClient.find(({ clientName }) => clientName === value) || {
          clientName: value,
          alias: '',
          isEnabled: false,
        }
      );
    },
    [ssoClient]
  );

  const addEntitlement = useCallback(() => {
    handleChange({
      event: ADD,
    });
  }, []);

  const config = useMemo(() => {
    return ssoClient.map(client => {
      return data.find(entl => entl.value === client.clientName);
    });
  }, [ssoClient, data]);

  return (
    <div className='profile-main-container__info-section-container'>
      <div data-testid={testId} className='profile-main-container__header-title'>
        {t('tkThirdPartyPartnerSsoEntitlements')}
      </div>
      {config.map((coreItem, idx) => {
        const configObject = { ...coreItem, ssoObject: ssoClient, ssoValidation: userDetailsValidation };
        return (
          <FieldRenderer
            key={coreItem.id}
            isEditable={isEditable}
            config={configObject}
            value={getSSOValue(coreItem.value).alias}
            handleChange={handleChange}
            sectionStart={idx === 0}
            fieldIndex={idx}
            errorState={userDetailsValidation[idx]}
          />
        );
      })}

      {isEditable && ssoClient.length < 6 && (
        <Button data-testid='add-entitlement' actionType='primary' emphasis='minimal' onClick={addEntitlement}>
          <Icon name='add' type='filled' size='sm' />
          {t('tkAddSsoEntitlementButton')}
        </Button>
      )}
    </div>
  );
}

SsoEntitlementsGroup.propTypes = {
  data: PropTypes.array,
  testId: PropTypes.string,
};
