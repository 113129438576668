export { Deferred } from 'gs-uitk-promise-utils';

// TODO: Remove in v19 as these are already exported by gs-uitk-text-utils
export {
    detectIndentStr,
    formatTextBlock,
    formatTextBlockTpl,
    removeLeadingIndent,
} from 'gs-uitk-text-utils';

import { getRandomNumber } from './utils/getRandomNumber';
import { getUniqueNumber } from './utils/getUniqueNumber';
import { getGradientOverlay } from './utils/getGradientOverlay';
import { getOverflowStyles } from './utils/text-overflow-utils';
import { KeyHelpers } from './utils/key-code-helpers';
import { Logger } from './utils/logger';
import { Mixin } from './utils/mixin';
import { UiToolkitResizeObserver } from './utils/resize-observer';
import { throttle } from './utils/throttle';

export { getGlobalThis } from './utils/get-global-this';
export * from './utils/array-utils';
export * from './utils/browser-utils';
export * from './utils/element-utils';
export * from './utils/hex-to-rgb';
export * from './utils/window';
export * from './utils/toolkit-version-checker';
export * from './utils/object-utils';
export * from './utils/query-string';
export * from './utils/string-utils';
export * from './utils/reflow';
export { assertNever } from './utils/assert-never';
export { copyToClipboard } from './utils/copy-to-clipboard';
export { Injectable } from './utils/injectable';
export { InsideOutsideEventListener } from './utils/inside-outside-event-listener';
export { logWarningOnce } from './utils/log-warning-once';
export { nonNullableValueOrThrow } from './utils/non-nullable-value-or-throw';
export { sleep } from './utils/sleep';
export { requestAnimationFrame } from './utils/request-animation-frame';
export { sanitizeHtml, unsanitizeHtml } from './utils/sanitize-html';

export * from './events/ui-event';
export * from './events/create-cross-browser-event';

export {
    getRandomNumber,
    getUniqueNumber,
    getGradientOverlay,
    getOverflowStyles,
    KeyHelpers,
    Logger,
    Mixin,
    throttle,
    UiToolkitResizeObserver,
};
