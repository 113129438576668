import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import translator from '../../../../../services/translator';
import { fundIdentifierPreference } from '../../../../../selectors/preferences';
import {
  cleanupGridData,
  diffArrays,
  getFundDisplay,
  refreshFundsOrBenchmarkSeriesDataOnChart,
  setMetricBenchmarksToState,
  getRecentShareclassAndBenchmarkIds,
  updateChartSeries
} from '../../../utils';
import { useAsync } from '../../../utils/hooks';
import { benchmarkMetricsOptionsSelector } from '../../../../../selectors/pages/fundTracker';
import Link from '../../../../../components/core/Link';
import { Conditional } from '../../../../../components/core/Conditional';
import './index.scss';
import { setShareClassPerformanceData } from '../../../store/fund-tracker-slice';
import { fundTrackerStateProps } from '../../../store/helpers';
import OrderedList from '../../../../../components/core/OrderedList';
import { DATA_TYPE } from '../../../constants';
import { saveAppPreferences } from '../../../../../actions/preferences';
import { getProductName, getProductDisplayedDetails } from '../../../utils/helpers';

const { translate: t } = translator;

const SubHeader = ({
  handleBackClick,
  isShowBackLink = true,
  allShareClassData = [],
  asOfDate: finalDate,
  benchmarks,
  benchmarkMetricsOptions,
  chartInstance,
  chartOptions,
  fieldForQuery,
  fundIdentifier,
  metrics: selectedMetrics,
  primaryProduct,
  selection,
  // TODO: rename to productPerformanceData
  shareClassPerformanceData,
  // TODO: rename to include bench and fund
  shareclass = {},
  isApplyRebates
}) => {
  const chartDispatch = useDispatch();
  const [chartLoading, setChartLoading] = useState(false);
  const isShareClassInfoLoading = !shareclass?.type;

  const { run } = useAsync(chartDispatch);
  const [primaryMetricItem] = selectedMetrics;

  // ***************************************************************************************
  //            To remove Metrics and Funds/Benchmark series from chart
  // ***************************************************************************************
  const removeMetricsAndBenchmarksFromSeries = (selectedFundOrBenchmarks, metricsItemToRemove, isApplyRebates) => {
    const fundOrBenchmarksToRemove = diffArrays(
      benchmarks,
      selectedFundOrBenchmarks,
      (v1, v2) => v1.value === v2.value
    );
    return {
      fundOrBenchmarksToRemove,
      updatedChartOptions: updateChartSeries(
        metricsItemToRemove,
        fundOrBenchmarksToRemove,
        { chartOptions, chartInstance, isApplyRebates, primaryProduct },
        primaryMetricItem.value
      )
    };
  };

  const onRemoveFundOrBenchmark = idToRemove => {
    setChartLoading(true);
    // remove the selected fund or benchmark from current list of benchmarks and funds
    const selectedFundOrBenchmarksData = benchmarks.filter(benchmark => benchmark.value !== idToRemove);

    // Get the metric item to be removed from series
    const metricsItemToRemove = undefined;
    const { fundOrBenchmarksToRemove, updatedChartOptions } = removeMetricsAndBenchmarksFromSeries(
      selectedFundOrBenchmarksData,
      metricsItemToRemove
    );
    // get updated recent benchmarks and funds list
    const { lastBenchmarkVendorIds, lastShareclassIds } = getRecentShareclassAndBenchmarkIds(
      selectedFundOrBenchmarksData,
      primaryProduct
    );

    // Save the user preferred funds and benchmakrs
    chartDispatch(
      saveAppPreferences({
        preferenceRootKey: 'fundTracker',
        preferenceData: {
          lastBenchmarkVendorIds,
          lastShareclassIds
        }
      })
    );

    const updatedFundAndBenchmarkList = allShareClassData.filter(data => data.id !== idToRemove);
    // TODO: turn params into an object
    refreshFundsOrBenchmarkSeriesDataOnChart(
      updatedFundAndBenchmarkList,
      run,
      fieldForQuery,
      benchmarkMetricsOptions,
      selectedFundOrBenchmarksData,
      chartDispatch,
      updatedChartOptions,
      // TODO: Expect primary benchmark id as well
      { allShareClassData, chartInstance, primaryProduct, isApplyRebates },
      [primaryMetricItem]
    );

    const currPerformanceData = diffArrays(shareClassPerformanceData, fundOrBenchmarksToRemove, (v1, v2) => {
      return v1.id === v2.value;
    });
    chartDispatch(
      setShareClassPerformanceData({
        results: currPerformanceData,
        asOfDate: finalDate
      })
    );

    // Add/remove data from bottom grid
    cleanupGridData(
      selectedFundOrBenchmarksData,
      fundOrBenchmarksToRemove,
      allShareClassData,
      primaryProduct,
      chartDispatch
    );

    // Set the selected benchmark items to context
    const updatedData = {
      benchmarks: selectedFundOrBenchmarksData,
      selection: !!selectedFundOrBenchmarksData.length && 'benchmarks'
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
    setTimeout(() => setChartLoading(false), 1000);
  };

  /** handle removal of clicked item when the chart is not loading */
  const onDismissItem = clickedItemId => {
    if (!chartLoading) {
      onRemoveFundOrBenchmark(clickedItemId);
    }
  };

  const fundDisplay = getFundDisplay(shareclass, fundIdentifier);

  // extract fund/benchmark details for display on screen
  const {
    isInstitutional,
    type,
    name: longName,
    nav: {
      asOfDate, navChange, value
    },
    navType
  } = getProductDisplayedDetails(shareclass);
  const onCompareMode = allShareClassData.length > 1 && selection !== 'metrics';
  const subHeaderName = getProductName(type, longName, fundDisplay, onCompareMode, t('tkCompareFunds'));
  let navChangeValue = navChange;
  if (navChange >= 0) navChangeValue = `+${navChange}`;

  /** retrieves the fund and benchmarks to display in list */
  const displayedFundsAndBenchmarks = allShareClassData.map(item => {
    if (item.type === DATA_TYPE.BENCHMARK) {
      const { id, name, category } = item;
      // fallback is category although it should always have a name
      const value = name || category;
      return { id, value };
    }
    const { shareclass: { id, longName } } = item;
    const value = `${longName} (${getFundDisplay(item, fundIdentifier)})`;
    return { id, value };
  });

  const { points: datapoints = [] } = allShareClassData.length > 0 ? allShareClassData[0] : {};
  const hasDatapoint = datapoints.length > 0;
  const isBenchmark = type === DATA_TYPE.BENCHMARK;

  return (
    !isShareClassInfoLoading && (
      <>
        <Conditional condition={isShowBackLink}>
          <div className='back'>
            <Link customClass='back' handleClick={handleBackClick} label={`< ${t('tkBack')}`} />
          </div>
        </Conditional>
        <div className='sub-header'>
          <div className='sub-header__name'>{subHeaderName}</div>
          {!onCompareMode && (
            <div className='sub-header__classdetails'>
              <div className='section'>
                <span>{t('tkClass')} </span>
                <Conditional condition={!isBenchmark}>
                  <span className='classtype'>
                    {isInstitutional ? t('tkInstitutional') : t('tkNonInstitutional')}
                  </span>
                  <span>{t('tkNA2')}</span>
                </Conditional>
              </div>
              <div className='section'>
                <span>{t('tkNavColon')}</span>
                <span className='value'>{!isBenchmark && hasDatapoint ? value : t('tkNA2')}</span>
              </div>
              {!isBenchmark && hasDatapoint && (
                <React.Fragment>
                  <div className='section'>
                    <span className='classtype'>
                      {navType !== 'CNAV' && navType !== 'LVNAV' && navType !== 'PDCNAV'
                        ? `(${navChangeValue})`
                        : navChangeValue}
                    </span>
                  </div>
                  <div>
                    <span className='classtype datetime'>
                      {asOfDate !== '--' ? moment(asOfDate).format('DD MMMM') : asOfDate}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
          {onCompareMode && (
            <OrderedList
              list={displayedFundsAndBenchmarks}
              onDismissItem={onDismissItem}
              disableDismissButton={chartLoading}
            />
          )}
        </div>
      </>
    )
  );
};

SubHeader.propTypes = {
  handleBackClick: PropTypes.func,
  isShowBackLink: PropTypes.bool,
  allShareClassData: PropTypes.array,
  asOfDate: PropTypes.string,
  benchmarkMetricsOptions: PropTypes.object,
  benchmarks: PropTypes.array,
  chartInstance: PropTypes.object,
  chartOptions: PropTypes.object,
  fieldForQuery: PropTypes.string,
  fundIdentifier: PropTypes.string,
  metrics: PropTypes.array,
  primaryProduct: PropTypes.string,
  selection: PropTypes.string,
  shareClassPerformanceData: PropTypes.array,
  shareclass: PropTypes.object,
  isApplyRebates: PropTypes.bool
};

const combinedMapStateToProps = state => ({
  fundIdentifier: fundIdentifierPreference(state),
  benchmarkMetricsOptions: benchmarkMetricsOptionsSelector(state),
  ...fundTrackerStateProps(state)
});

export default withRouter(connect(combinedMapStateToProps, null)(SubHeader));
