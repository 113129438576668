import Highcharts from 'highcharts/highstock';
import { CHART_MESSAGES } from './constants';
import { composeTranslatedLabels } from '../../../utils/stringUtils';
import { PLOTLINE_ID } from '../../../modules/FundTracker/constants';

/** given series for a given chart, fn determines # of items with no data */
export function getNoDataCount(series = []) {
  return series.filter(({ noData }) => noData).length;
}

/** fn will render provided message in the center of the chart */
export function displayChartMessage(message) {
  const chart = this;

  if (chart) {
    // Remove any existing no-data text
    if (chart.noDataLabel) {
      chart.noDataLabel.destroy();
      chart.noDataLabel = null;
    }
    // Add a text element to the chart
    chart.noDataLabel = chart.renderer
      .text(message, chart.plotWidth / 2 + chart.plotLeft, chart.plotHeight / 2 + chart.plotTop, true)
      .css({
        color: '#666666',
        fontSize: '16px'
      })
      .attr({
        align: 'center'
      })
      .add();
  }
}

export function getPlotline({ id = PLOTLINE_ID, value, labelDate, point }) {
  return {
    id,
    point,
    value,
    width: 1,
    dashStyle: 'dash',
    color: 'black',
    label: {
      useHTML: true,
      text: `<div style='color: black; background: lightgrey; text-align: center'>${labelDate} <br/> ${point.series.name}: ${point.y}</div>`,
      rotation: 0,
      align: 'center',
      verticalAlign: 'bottom'
    }
  };
}

export function handleClickedPoint(e, callback) {
  const myPlotLineId = PLOTLINE_ID;
  const { point = {} } = e;
  const { x: xValue, series } = point;
  const selectedDate = Highcharts.dateFormat('%Y-%m-%d', xValue);
  const labelDate = Highcharts.dateFormat('%d.%B.%Y', xValue);
  const { xAxis } = series;
  if (xAxis.plotLinesAndBands.length === 0) {
    const plotLine = getPlotline({ labelDate, value: xValue, id: myPlotLineId, point });
    xAxis.addPlotLine(plotLine);
    return callback({ selectedDate });
  }

  const prevValue = xAxis.plotLinesAndBands[0].options.value;
  if (xValue === prevValue) {
    try {
      xAxis.removePlotLine(myPlotLineId);
    } catch (err) {
      console.log('Error occurred while removing plotline', err);
    }
    callback({ selectedDate: null });
  } else {
    xAxis.removePlotLine(myPlotLineId);
    const plotLine = getPlotline({ labelDate, value: xValue, id: myPlotLineId, point });
    xAxis.addPlotLine(plotLine);
    callback({ selectedDate });
  }
}

export function getChartMessage(messageCode, input, isSingleMetrics = true) {
  // when there is no input, we ensure not to show any message
  if (!input) return null;

  const { METRIC_UNAVAILABLE, SOME_METRIC_UNAVAILABLE, METRICS_UNAVAILABLE } = CHART_MESSAGES;
  switch (messageCode) {
    case METRIC_UNAVAILABLE:
      return `${input} ${composeTranslatedLabels([
        'tkIs',
        'tkUnavailable',
        'tkFor',
        'tkYour',
        'tkSelection'
      ]).toLowerCase()}`;
    case METRICS_UNAVAILABLE:
      return `${input.map(metric => metric.label).join(` ${composeTranslatedLabels(['tkand'])} `)} ${composeTranslatedLabels([
        'tkare',
        'tkUnavailable',
        'tkFor',
        'tkYour',
        'tkSelection'
      ]).toLowerCase()}`;
    case SOME_METRIC_UNAVAILABLE:
      // we only show the "for one or more selections" when there are some missing series and a single metric selected
      return `${input} ${composeTranslatedLabels([
        'tkIs',
        'tkUnavailable',
        ...(isSingleMetrics ? ['tkFor',
        'tkOneOrMore',
        'tkSelections'] : [])
      ]).toLowerCase()}`;
    default:
      return '';
  }
}

/** util will return today's date (midnight) */
export function getTodayMidnight() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
};
