import React, { useEffect, useState, useCallback } from 'react';
import { Select } from '@gs-ux-uitoolkit-react/select';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../context';
import { ssoLabelOptions, CHANGE } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const DropdownLabel = props => {
  const { title, value, fieldIndex, errorState: { errorMessage } = {}, handleChange, ssoDropdownValue, setSsoDropdownValue } = props;
  const {
    state: { userDetailsValidation, userDetails: { ssoClient = [] } = {} },
  } = useUserMaintenance();

  const [filteredLabelOptions, setFilteredLabelOptions] = useState(ssoLabelOptions);

  useEffect(() => {
    const selectedValue =
      ssoLabelOptions.find(option => {
        return option.label === title;
      }) || '';
    setSsoDropdownValue(selectedValue.value);
  }, [ssoLabelOptions, title, value]);

  useEffect(() => {
    const updatedOptions = filteredLabelOptions.map(option => ({
      ...option,
      disabled: ssoClient.some(client => client.clientName === option.value),
    }));
    setFilteredLabelOptions(updatedOptions);
  }, [ssoClient, ssoDropdownValue]);

  const handleSelectChange = useCallback(event => {
    setSsoDropdownValue(event.selectedValue);
    const deletedClient = ssoClient?.find(client => client.name === event.selectedValue);
    handleChange({
      event: CHANGE,
      fieldIndex,
      clientName: event.selectedValue,
      alias: deletedClient ? deletedClient.alias : '',
      errorState: parseInt(Object.keys(userDetailsValidation)[0]) === fieldIndex,
    });
  }, []);

  return (
    <div data-testid='dropdown-label'>
      <Select
        className={'profile-dropdown labelDropdown'}
        options={filteredLabelOptions}
        searchable={false}
        onSelect={handleSelectChange}
        placeholder={t('tkPleaseSelect')}
        selectedValue={ssoDropdownValue}
        clearable={false}
      />
      {errorMessage && (
        <span className='error-message' data-testid='text-errorMessage'>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

DropdownLabel.propTypes = {
  errorState: PropTypes.object,
  value: PropTypes.string,
  ssoDropdownValue: PropTypes.string,
  setSsoDropdownValue: PropTypes.func,
  title: PropTypes.string,
  fieldIndex: PropTypes.number,
  handleChange: PropTypes.func,
};

export default DropdownLabel;
