import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import XLSX from 'xlsx-js-style';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import ReactToPrint from 'react-to-print';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import moment from 'moment-timezone';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { DATE_TIME_FORMATTERS, FILE_DOWNLOAD_TYPES } from '../../../../constants/appConstants';
import { controlPanleMapDispatchToProps } from '../../../Grid/mapDispatchToProps';
import { isOpenFileDownloadModal } from '../../../../selectors/app/reports';
import {
  getFileTypes,
  getSelectedFileType,
  isRebateExistsForSomeFundSelector,
  metricsOptionsSelector
} from '../../../../selectors/pages/fundTracker';
import Modal from '../../../../components/core/Modal';
import RadioGroup from '../../../../components/app/RadioGroup';
import Checkbox from '../../../../components/core/CheckBox';
import Select from '../../../../components/core/Select/Simple';
import {simpleRenderer} from '../ChartDropdown/renderer';
import Button from '../../../../components/core/Button';
import {getFundDisplay} from '../../utils';
import {fundIdentifierPreference} from '../../../../selectors/preferences';
import {pageIdSelector} from '../../../../selectors/pages';
import translator from '../../../../services/translator';
import {Conditional} from '../../../../components/core/Conditional';
import {fetchFundTrackerExportData} from '../../services';
import {useAsync} from '../../utils/hooks';
import {
  columnAttributes,
  getCoverPageData,
  getData,
  getWidth,
  setCoverPageSheetStyle,
  setDisclaimerStyle,
  setSheetStyle
} from './exportUtils';
import {ThreeSquaresLoader} from '../../../../components/core/Loaders';
import {userSelector} from '../../../../selectors/user';
import {staticDisclaimersSelector} from '../../../../selectors/app';
import {saveAppPreferences} from '../../../../actions/preferences';
import { fundTrackerStateProps } from '../../store/helpers';
import { formatFilenameWithDate } from '../../../../utils/formatters';
import { DATA_TYPE } from '../../constants';
import { capitalizeString, composeTranslatedLabels } from '../../../../utils/stringUtils';
import './index.scss';

const {translate: t} = translator;

const twoYearTimeInSecs = (2 * 365 * 24 * 60 * 60);

export const FundTrackerDataDownloadModal = (props) => {
  const {
    metricOptions, fundIdentifier, currentPageId, selectedFileType, open,
    options, dispatchChangeFileType, dispatchCloseModal, componentRef,
    isPrintingInProgress, setIsPrintingInProgress, userInfo, staticDisclaimer,
    saveAppPreferences, allShareClassData = [], shareclass = {},
    chartInstance, metrics, selection, excelExportData, isRebateExistsForSomeFund
  } = props;
  const chartDispatch = useDispatch();
  const {run} = useAsync(chartDispatch);
  const fundDisplay = getFundDisplay(shareclass, fundIdentifier);
  const downloadModalRef = useRef();
  const initialId = metricOptions.map(item => (item.value === currentPageId));
  const [checkedIds, setCheckedIds] = useState(initialId);
  const {shareclass: {longName} = {}, name} = shareclass;
  const startDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d.%b.%Y', chartInstance.xAxis[0].getExtremes().min);
  const endDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%d.%b.%Y', chartInstance.xAxis[0].getExtremes().max);
  const d1 = new Date(startDate).getTime();
  const d2 = new Date(endDate).getTime();
  const getTimeDiff = ((new Date(d2).getTime() - new Date(d1).getTime()) / 1000);
  const lessThan2YearData = (getTimeDiff <= twoYearTimeInSecs);
  const [selectedOption, setSelectedOption] = useState({label: t('tkDaily')});
  const frequencyOptions = useMemo(() => ([
    {label: t('tkDaily')},
    {label: t('tkMonthly')},
    {label: t('tkWeekly')}
  ]));
  const enableMetricSelection = (selection !== 'metrics') && lessThan2YearData;
  const customModalStyle = {overflow: 'hidden'};
  const onBeforeGetContentResolve = useRef(null);
  const [prePrintStatus, setPrePrintStatus] = useState('');

  const checkID = (ids) => {
    if (metrics.length > 0) {
      const [primaryMetric, secondaryMetric = {}] = metrics;
      return ids.some(({label}) => (
        [primaryMetric.label, secondaryMetric.label].includes(label)
      ));
    }
    return false;
  };

  useEffect(() => {
    setCheckedIds(initialId);
  }, [metricOptions]);

  useEffect(() => {

  }, [selectedFileType]);

  const onSetCheckedIdsChange = (event) => {
    const updatedCheckedIds = metricOptions.map((item, index) => {
      return (item.value === event) ?
        !checkedIds[index] : checkedIds[index];
    });
    setCheckedIds(updatedCheckedIds);
  };

  const handleClose = () => {
    dispatchChangeFileType(null);
    dispatchCloseModal({'modelActiveMode': '', 'isModalLoading': false});
  };

  const handleFileTypeChange =(fileType) => {
    dispatchChangeFileType(fileType);
  };

  const onFileTypeChange = (event, isChecked, option) => {
    handleFileTypeChange(option.id);
  };

  const onClose = (status) => {
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  const handleBeforePrint = React.useCallback(() => {
    setIsPrintingInProgress(true);
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    setIsPrintingInProgress(false);
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        'downloadFileType': selectedFileType
      }
    });
    downloadModalRef.current.closeModal(() => handleClose());
  }, [selectedFileType]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrePrintStatus('Loading new text...');
    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve;
      setPrePrintStatus('New, Updated Text!');
      resolve();
    });
  }, [setPrePrintStatus]);

  useEffect(() => {
    if (prePrintStatus === 'New, Updated Text!' && typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, prePrintStatus]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => (
    <button
      className={cn('button button__primary button__primary--small')}
      onClick={() => {setIsPrintingInProgress(true);}}>
      {t('tkDownload')}
    </button>
  ), []);

  const pdfFileName = formatFilenameWithDate('FundTracker_Report', {
    format: DATE_TIME_FORMATTERS.DDMMMYYYYHHmmss,
    toUpperCase: false
  });

  const subHeading = useMemo(() => {
    let fundInfo = '';
    const dateRange = `${startDate} - ${endDate}`;

    if (allShareClassData.length > 1) {
      fundInfo = `${allShareClassData.length} ${t('tkFundsSelected')}`;
    } else {
      fundInfo = fundDisplay && `${longName} (${fundDisplay})` || longName || name;
    }
    return `${fundInfo}, ${dateRange}`;
  });

  const updatedMetricOptions = useMemo(() => (
    metricOptions.map((item, index) => {
      const isChecked = enableMetricSelection ? checkedIds[index] : checkID(item.items);
      return {...item, isChecked};
    })
  )) || [];

  const isDownloadButtonDisabled = (selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL)
    && !isEmpty(updatedMetricOptions)
    && !updatedMetricOptions.some(({isChecked}) => isChecked) || false;

  const shareclassIds = allShareClassData.filter(({ type }) => type === DATA_TYPE.FUND).map(i => i.id);
  const benchmarkIds = allShareClassData.filter(({ type }) => type === DATA_TYPE.BENCHMARK).map(i => i.id);

  useEffect(() => {
    if (excelExportData.length > 0) {
      const workbook = XLSX.utils.book_new();
      const headers = updatedMetricOptions.reduce((result, data, index) => {
        if (data.isChecked) {
          const headerLabels = metricOptions[index].items
            .filter(({isRebateField}) => isRebateExistsForSomeFund ? true : !isRebateField)
            .map(({label}) => label);
          return [...result, ...headerLabels];
        }
        return result;
      }, []);
      const coverPageData = getCoverPageData({ allShareClassData, headers });
      const coverPageWorksheet = XLSX.utils.json_to_sheet(coverPageData, { skipHeader: true, origin: 'A7' });
      XLSX.utils.book_append_sheet(workbook, coverPageWorksheet, t('tkCoverPage'));
      const width = [0, 0];
      coverPageData.map(row => (width[0] = Math.max(row.label.length, width[0])));
      coverPageData.map(row => (width[1] = Math.max(row.value.length, width[1])));
      coverPageWorksheet['!cols'] = width.map(w => ({ wch: w + 25 }));

      const startDate =
        chartInstance &&
        chartInstance.xAxis &&
        Highcharts.dateFormat('%d, %b %Y', chartInstance.xAxis[0].getExtremes().min).toUpperCase();
      const endDate =
        chartInstance &&
        chartInstance.xAxis &&
        Highcharts.dateFormat('%d, %b %Y', chartInstance.xAxis[0].getExtremes().max).toUpperCase();

      XLSX.utils.sheet_add_json(
        coverPageWorksheet,
        [
          { note: `${t('tkReport')}: ${composeTranslatedLabels(['tkFundTrackerMetrics'], { upperCase: true })}` },
          { note: `${startDate} - ${endDate}` }
        ],
        { header: ['note'], skipHeader: true, origin: 'A1' }
      );
      const timeZone = moment.tz.guess();
      const currentDateWithTime = moment(new Date()).tz(timeZone).format('DD.MMM.YYYY hh:mmA z').toUpperCase();
      XLSX.utils.sheet_add_json(
        coverPageWorksheet,
        [
          {
            note: `${capitalizeString(t('tkCreatedBy'))}: ${userInfo.firstName.charAt(0)}. ${userInfo.lastName} ${t(
              'tkon'
            )} ${currentDateWithTime}`,
            note1: composeTranslatedLabels(['tkConfidential', '-', 'tkForInternalUseOnly'], { titleCase: true })
          }
        ],
        { header: ['note', 'note1'], skipHeader: true, origin: `A${coverPageData.length + 11}` }
      );
      setCoverPageSheetStyle(coverPageData, coverPageWorksheet, allShareClassData);

      let maxWidths = Array(8).fill(0);
      const div = document.createElement('div');
      for (let i = 0; i < headers.length; i++) {
        const headerLabel = headers[i];
        const data = getData(excelExportData, selectedOption, headerLabel, columnAttributes[headerLabel]);
        const worksheet = XLSX.utils.json_to_sheet(data, { origin: 'A2' });
        const productGroup = metricOptions.find(i => i.items.some(item => item.label === headerLabel)).label;
        const productGroupValue = metricOptions.find(i => i.items.some(item => item.label === headerLabel)).value;
        maxWidths = getWidth(data, maxWidths, worksheet);
        XLSX.utils.sheet_add_json(worksheet, [{ note1: `${productGroup} Metrics-${headerLabel}` }], {
          header: ['note1'],
          skipHeader: true,
          origin: 'A1'
        });
        XLSX.utils.sheet_add_json(worksheet, [{ note2: t('tkDynamicDisclosureComponent') }], {
          header: ['note2'],
          skipHeader: true,
          origin: `A${data.length + 4}`
        });

        let text = staticDisclaimer[`fundtracker${productGroupValue}GroupDisclaimerData`];
        text = text.replace('<br>', '\n');
        div.innerHTML = text;
        XLSX.utils.sheet_add_json(worksheet, [{ note3: div.innerText }], {
          header: ['note3'],
          skipHeader: true,
          origin: `A${data.length + 6}`
        });
        setSheetStyle(data, worksheet, div.innerText);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          headerLabel.replace(':', '').length > 31 ? 'NSF(MM)' : headerLabel.replace(':', '')
        );
      }
      workbook.SheetNames.forEach((key, index) => {
        if (index !== 0) workbook.Sheets[key]['!cols'] = maxWidths.map(w => ({ wch: w }));
      });

      const disclaimerData = [];
      let text = staticDisclaimer.fundTrackerShortDisclaimerData;
      text = text.concat('\n').concat(staticDisclaimer.fundtrackerDisclaimerData);

      updatedMetricOptions.forEach(id => {
        if (id.isChecked) {
          text = text.concat('\n\n');
          text = text.concat(staticDisclaimer[`fundtracker${id.value}GroupDisclaimerData`]);
        }
      });
      text = text.replace('<br>', '\n');
      div.innerHTML = text;
      disclaimerData.push({ label: div.innerText });
      const disclaimerWorksheet = XLSX.utils.json_to_sheet(disclaimerData, { skipHeader: true });
      setDisclaimerStyle(disclaimerWorksheet, disclaimerData);
      XLSX.utils.book_append_sheet(
        workbook,
        disclaimerWorksheet,
        composeTranslatedLabels(['tkDisclaimers'], { titleCase: true })
      );

      const filename = formatFilenameWithDate('FundTracker_Report', {
        format: DATE_TIME_FORMATTERS.DDMMMYYYYHHmmss,
        extension: 'xlsx',
        toUpperCase: false
      });
      XLSX.writeFile(workbook, filename);
    }
  }, [excelExportData]);

  const handleFileDownload = (status) => {
    const fieldValue = [];
    updatedMetricOptions.forEach((id, index) => {
      if (id.isChecked) {
        metricOptions[index].items.forEach(({isHideFromUI, value}) => {
          !isHideFromUI && fieldValue.push(value);
        });
      }
    });
    const startDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%Y-%m-%d', chartInstance.xAxis[0].getExtremes().min);
    const endDate = chartInstance && chartInstance.xAxis && Highcharts.dateFormat('%Y-%m-%d', chartInstance.xAxis[0].getExtremes().max);
    fetchFundTrackerExportData(run, chartDispatch, startDate, endDate, shareclassIds, fieldValue, benchmarkIds);
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        'downloadFileType': selectedFileType
      }
    });
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  return(
    <Modal
      ref={downloadModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass='modal--center ft-download-modal__index'
      backdropCustomClass='ft-download-modal__backdrop-index'
      open={open}
      handleClose={handleClose}
      animation={false}
    >
      <Conditional condition={isPrintingInProgress}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className='ft-download-modal'>
        <div className='ft-download-modal__heading'>{t('tkDownload')}</div>
        <div className='ft-download-modal__sub-heading'>{subHeading}</div>
        <div className='ft-download-modal__inputContainer'>
          <div className='ft-download-modal__section'>
            <div className='ft-download-modal__section--title'>{t('tkSelectYourFormat')}:</div>
            <div>
              <RadioGroup
                testId='fundtracker-file-download-radio-group'
                onChange={onFileTypeChange}
                options={options}
              />
            </div>
          </div>
          <Conditional condition={selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL}>
            <>
              <div className='ft-download-modal__section'>
                <div  className='ft-download-modal__section--title'>
                  {t('tkSelectFundMetricsToInclude')}
                </div>
                <div className='metrics'>
                  {
                    updatedMetricOptions.map((item) => (
                      <Checkbox
                        value={item.value}
                        id={item.label}
                        isChecked={item.isChecked}
                        label={item.label}
                        isDisabled={!enableMetricSelection}
                        changeHandler={onSetCheckedIdsChange}
                      />
                    ))
                  }
                </div>
              </div>
              <Conditional condition={!lessThan2YearData}>
                <div className='ft-download-modal__section'>
                  <Alert
                    status='information'
                    emphasis='subtle'>
                    {t('tkFundMetricsDownloadAlert')}
                  </Alert>
                </div>
              </Conditional>
              <div className='ft-download-modal__section'>
                <div  className='ft-download-modal__section--title'>{t('tkSelectFrequencyOfFundMetrics')}</div>
                <div className='margin-neg-8px'>
                  <Select
                    valueKey='value'
                    clickHandler={(selectedOption => setSelectedOption(selectedOption))}
                    selectedOption={selectedOption}
                    isActive={true}
                    hasError={false}
                    optionRenderer={simpleRenderer}
                    labelKey='label'
                    options={frequencyOptions}
                    testId='test-metrics'
                  />
                </div>
              </div>
            </>
          </Conditional>
        </div>
        <div className='ft-download-modal__buttonRow'>
          <Button label={t('tkCancel')}   customClass='button-secondary-small' clickHandler={(event) => onClose(event)} />
          <Conditional condition={selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL}>
            <Button
              label={t('tkDownload')}
              customClass='button-primary-small'
              isDisabled={isDownloadButtonDisabled}
              clickHandler={(event) => handleFileDownload(event)}
            />
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={pdfFileName}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </Conditional>
        </div>
      </div>
    </Modal>
  );
};

export const mapStateToProps = (state, {id}) => ({
  open: isOpenFileDownloadModal(state),
  options: getFileTypes(state, id),
  selectedFileType: getSelectedFileType(state, id),
  metricOptions: metricsOptionsSelector(state),
  isRebateExistsForSomeFund: isRebateExistsForSomeFundSelector(state),
  fundIdentifier: fundIdentifierPreference(state),
  currentPageId: pageIdSelector(state),
  userInfo: userSelector(state),
  staticDisclaimer: staticDisclaimersSelector(state),
  ...fundTrackerStateProps(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...controlPanleMapDispatchToProps(dispatch, ownProps),
    saveAppPreferences: data => dispatch(saveAppPreferences(data)),
  };
};

FundTrackerDataDownloadModal.propTypes = {
  open: PropTypes.bool,
  dispatchCloseModal: PropTypes.func,
  options: PropTypes.array,
  dispatchChangeFileType: PropTypes.func,
  selectedFileType: PropTypes.string,
  metricOptions: PropTypes.array,
  fundIdentifier: PropTypes.string,
  currentPageId: PropTypes.string,
  componentRef: PropTypes.object,
  isPrintingInProgress: PropTypes.bool,
  setIsPrintingInProgress: PropTypes.func,
  userInfo: PropTypes.object,
  staticDisclaimer: PropTypes.object,
  allShareClassData: PropTypes.array,
  shareclass: PropTypes.object,
  chartInstance: PropTypes.object,
  metrics: PropTypes.array,
  selection: PropTypes.string,
  excelExportData: PropTypes.array,
  isRebateExistsForSomeFund: PropTypes.bool,
  saveAppPreferences: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FundTrackerDataDownloadModal);
