import { useState, useEffect, useMemo, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import useFetch from '../useFetch';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { dispatchAnalytics, maintenanceAlertsFilterClicks } from '../../analytics';

const useFetchDashBoardData = (moduleName, onSuccess, onError) => {
  const module = useMemo(() => {
    const config = {
      MaintenanceAlerts: 'MaintenanceAlerts',
    };
    return config[moduleName];
  }, [moduleName]);

  const url = useMemo(() => {
    const endpoint = cloneDeep(endPointsMapper.FETCH_SPECIFIC_DASHBOARD_MODULE);
    endpoint.path = `${endpoint.path}/${module}`;
    return endpoint;
  }, []);

  const { doFetch, loading, data, success, error } = useFetch(url, onSuccess, onError);

  useEffect(() => {
    doFetch();
  }, []);

  return { doFetch, loading, data, success, error };
};

const useDashBoard = (moduleName, defaultFilters, userGuid, reduxDispatch) => {
  const [activeFilters, setActiveFilters] = useState(defaultFilters);
  const { loading, data, error, doFetch } = useFetchDashBoardData(moduleName);
  const { filters, values } = data || {};
  useEffect(() => {
    if (filters && filters.length) {
      const updatedFilters = filters.map(filter => {
        const allFilter = defaultFilters.find(defaultFilter => defaultFilter.name === filter.name);
        return { ...filter, value: [...filter.value, allFilter.selected], selected: allFilter.selected };
      });
      setActiveFilters(updatedFilters);
    }
  }, [filters]);

  const onFiltersUpdate = useCallback((selected, filterName) => {
    setActiveFilters(oldState => oldState.map(filter => (filter.name === filterName ? { ...filter, selected } : filter)));
    moduleName === 'MaintenanceAlerts' && dispatchAnalytics(reduxDispatch, maintenanceAlertsFilterClicks({ filterName: selected, guid: userGuid }));
  }, []);

  const cardRows = useMemo(() => {
    if (!values) return [];
    if (!activeFilters.length) return values;
    return values.filter(value =>
      activeFilters.every(filter => {
        if (defaultFilters.some(item => item.selected === filter.selected)) return true;
        return value[filter.name] === filter.selected;
      })
    );
  }, [activeFilters, values]);

  return {
    loading,
    error,
    activeFilters,
    onFiltersUpdate,
    cardRows,
    values,
    reFetchDashBoardData: doFetch,
  };
};

export default useDashBoard;
