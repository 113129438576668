import {
  fundTrackerFieldSelector,
  isApplyRebatesFlagForInitFundLoadSelector,
  isApplyRebatesSelector,
  isRebateExistsForPrimaryProductSelector,
  isRebateExistsForSomeFundSelector
} from '../../../selectors/pages/fundTracker';
export const shareclassesAndBenchmarkStateProps = state => ({
  shareClassAndBenchmarkData: fundTrackerFieldSelector(state, 'shareClassAndBenchmarkData')
});

/**
 * default mapStateToProps function for FundTracker
 */
export const fundTrackerStateProps = state => ({
  allShareClassData: fundTrackerFieldSelector(state, 'allShareClassData'),
  asOfDate: fundTrackerFieldSelector(state, 'asOfDate'),
  benchmarks: fundTrackerFieldSelector(state, 'benchmarks'),
  chartInstance: fundTrackerFieldSelector(state, 'chartInstance'),
  chartOptions: fundTrackerFieldSelector(state, 'chartOptions'),
  excelExportData: fundTrackerFieldSelector(state, 'excelExportData'),
  fieldForQuery: fundTrackerFieldSelector(state, 'fieldForQuery'),
  isFundOrBenchmarkDataMissing: fundTrackerFieldSelector(state, 'isFundOrBenchmarkDataMissing'),
  isFundOrBenchmarkSeriesDataMissing: fundTrackerFieldSelector(state, 'isFundOrBenchmarkSeriesDataMissing'),
  isMetricDataMissing: fundTrackerFieldSelector(state, 'isMetricDataMissing'),
  metrics: fundTrackerFieldSelector(state, 'metrics'),
  primaryProduct: fundTrackerFieldSelector(state, 'primaryProduct'),
  selectedChartDateRange: fundTrackerFieldSelector(state, 'selectedChartDateRange'),
  selection: fundTrackerFieldSelector(state, 'selection'),
  shareClassPerformanceData: fundTrackerFieldSelector(state, 'shareClassPerformanceData'),
  shareclass: fundTrackerFieldSelector(state, 'shareclass'),
  thresholdList: fundTrackerFieldSelector(state, 'thresholdList'),
  isApplyRebatesFlagForInitFundLoad: isApplyRebatesFlagForInitFundLoadSelector(state),
  isApplyRebates: isApplyRebatesSelector(state),
  isRebateExistsForPrimaryProduct: isRebateExistsForPrimaryProductSelector(state),
  isRebateExistsForSomeFund: isRebateExistsForSomeFundSelector(state),
  isFetchingChartData: fundTrackerFieldSelector(state, 'isFetchingChartData'),
  snackbarMessages: fundTrackerFieldSelector(state, 'snackbarMessages'),
  ...shareclassesAndBenchmarkStateProps(state)
});
