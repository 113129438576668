import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getAppRoute } from '../../utils/commonUtils';
import { fundTrackerPreferenceChange, saveAppPreferences } from '../../actions/preferences';
import {
  getFundTrackerNavLinks, isPrintingInProgressSelector, snackbarListSelector
} from '../../selectors/pages/fundTracker';
import {
  applyRebatesSelector,
  preferredPrimaryFundsAndBenchmarks,
  lastShareclassIdsSelector,
  topOpeningBalanceShareclassIdsSelector,
  isMetricCompareModeSelector
} from '../../selectors/preferences';
import PrimaryProductSearch from './components/PrimaryProductSearch';
import MainHeader from './components/Headers/MainHeader';
import SubHeader from './components/Headers/SubHeader';
import ActionBar from './components/ActionBar';
import WithPageError from '../../containers/WithPageError';
import InjectRoutes from '../../routes';
import Constants from '../../constants/appConstants';
import { userGuidSelector } from '../../selectors/user';
import Snackbar from '../../components/core/Snackbar';
import { updateInitialConfiguration, setIsApplyRebates } from './store/fund-tracker-slice';
import { labelSelector, shareClassFromFundFinderOrPreferenceSelector } from '../../selectors/app';
import { isPrintingInProgress } from '../../actions/page/fundTracker';
import { addAppContext } from '../../actions/app';
import { MODULES_KEYS } from '../../constants/pageConstants';
import DataDownloadModal from './components/DataDownloadModal';
import Footer from './components/Footer';
import history from '../../utils/history';
import './index.scss';

const FundTracker = ({
  clearFundTrackerInitShareClassData, fundTrackerNavLinks, fundTrackerPreferenceChange, isPrintingInProgress,
  labels, lastShareclassIds, saveAppPreferences, setIsPrintingInProgress, shareClassFromFundFinderOrPreference,
  shareclassIdsFromPreference, snackbarList, subRoutes = [], topOpeningBalanceShareclassIds, userGuid,
  applyRebates, isMetricCompareMode
}) => {
  const [displayFooterDisclaimer, setDisplayFooterDisclaimer] = useState(false);
  const componentRef = React.useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shareclassIdsFromPreference && !isMetricCompareMode) {
      dispatch(updateInitialConfiguration({shareclassIdsFromPreference}));
    }
  }, [shareclassIdsFromPreference, isMetricCompareMode]);

  useEffect(() => {
    if (applyRebates !== undefined) {
      dispatch(setIsApplyRebates(applyRebates));
    }
  }, [applyRebates]);

  const setIsPrintingInProgressFlag = flag => {
    setIsPrintingInProgress(flag);
    setDisplayFooterDisclaimer(flag);
  };

  const saveUserPreferredFunds = updatedPreferences => {
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: updatedPreferences
    });
  };

  /*
    If user lands on Fund Tracker screen from 'Fund Finder' or 'Threshold Preference', then save the
    funds to preferences
  */
  useEffect(() => {
    const { primaryShareClassDetails } = shareClassFromFundFinderOrPreference || {};
    if (primaryShareClassDetails) {
      const { shareClassId } = primaryShareClassDetails;
      saveUserPreferredFunds({ lastShareclassIds: [shareClassId], lastBenchmarkVendorIds: [] });
    }
  }, [shareClassFromFundFinderOrPreference]);

  const isPreferenceOnlyHasTopHoldingFunds = useMemo(() => {
    const { primaryShareClassDetails } = shareClassFromFundFinderOrPreference || {};
    const isShareClassFromFundFinderOrPreference = !!primaryShareClassDetails;
    return (
      !isShareClassFromFundFinderOrPreference && !isEmpty(topOpeningBalanceShareclassIds) && isEmpty(lastShareclassIds)
    );
  }, [shareClassFromFundFinderOrPreference, topOpeningBalanceShareclassIds, lastShareclassIds]);

  useEffect(() => {
    /* If user landing on Fund Tracker screen (not via 'Fund Finder => TRACK Fund' or 'Threshold Preferences' screen) with only Top Five Holding
       Funds from their preferences (which was grabbed from portfolio screen), default them to PERFORMANCE Tab */
    if (isPreferenceOnlyHasTopHoldingFunds) {
      history.push(getAppRoute(Constants.FUND_TRACKER_PERFORMANCE));
    }
  }, [isPreferenceOnlyHasTopHoldingFunds]);

  const tailEndFundIds = !isEmpty(topOpeningBalanceShareclassIds) ? topOpeningBalanceShareclassIds : [];

  return (
    <div className='fundTracker'>
      <TransitionGroup component={null}>
        {snackbarList.map((data, idx) => (
          <CSSTransition key={idx} classNames='snackbar__transition' timeout={Constants.CSS_DURATION_HORSE}>
            <Snackbar labels={labels} {...data} />
          </CSSTransition>
        ))}
      </TransitionGroup>
      <PrimaryProductSearch
        userGuid={userGuid}
        saveUserPreferredFunds={saveUserPreferredFunds}
        fundTrackerPreferenceChange={fundTrackerPreferenceChange}
        tailEndFundIds={tailEndFundIds}
        clearFundTrackerInitShareClassData={clearFundTrackerInitShareClassData}
      />
      <SubHeader isShowBackLink={false} />
      <div className='fundTracker__container'>
        <div className='fundTracker__tabs'>
          <ActionBar />
        </div>
        <div className='fundTracker__section' ref={componentRef}>
          <div className={cn({ 'show-footer': displayFooterDisclaimer }, { 'hide-footer': !displayFooterDisclaimer })}>
            <div className='fundTracker__pdfHeader'>
              <MainHeader />
              <SubHeader isShowBackLink={false} />
            </div>
          </div>
          <WithPageError>
            <InjectRoutes
              routes={subRoutes}
              navLinks={fundTrackerNavLinks}
              redirectRoutes={[getAppRoute(Constants.FUND_TRACKER_ROUTE)]}
            />
          </WithPageError>
          <div className={cn({ 'show-footer': displayFooterDisclaimer }, { 'hide-footer': !displayFooterDisclaimer })}>
            <Footer authorized={true} />
          </div>
        </div>
      </div>
      <DataDownloadModal
        id={MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID}
        isPrintingInProgress={isPrintingInProgress}
        setIsPrintingInProgress={setIsPrintingInProgressFlag}
        componentRef={componentRef}
      />
    </div>
  );
};

FundTracker.propTypes = {
  clearFundTrackerInitShareClassData: PropTypes.object,
  fundTrackerNavLinks: PropTypes.array,
  fundTrackerPreferenceChange: PropTypes.func,
  isPrintingInProgress: PropTypes.bool,
  labels: PropTypes.object,
  lastShareclassIds: PropTypes.array,
  primaryProduct: PropTypes.object,
  saveAppPreferences: PropTypes.func,
  setIsPrintingInProgress: PropTypes.func,
  shareClassFromFundFinderOrPreference: PropTypes.object,
  shareclassIdsFromPreference: PropTypes.array,
  snackbarList: PropTypes.array,
  subRoutes: PropTypes.array,
  topOpeningBalanceShareclassIds: PropTypes.array,
  userGuid: PropTypes.string,
  applyRebates: PropTypes.bool,
  isMetricCompareMode: PropTypes.bool
};

const mapStateToProps = state => ({
  fundTrackerNavLinks: getFundTrackerNavLinks(state),
  isPrintingInProgress: isPrintingInProgressSelector(state),
  labels: labelSelector(state),
  lastShareclassIds: lastShareclassIdsSelector(state),
  shareClassFromFundFinderOrPreference: shareClassFromFundFinderOrPreferenceSelector(state),
  shareclassIdsFromPreference: preferredPrimaryFundsAndBenchmarks(state),
  snackbarList: snackbarListSelector(state),
  topOpeningBalanceShareclassIds: topOpeningBalanceShareclassIdsSelector(state),
  userGuid: userGuidSelector(state),
  applyRebates: applyRebatesSelector(state),
  isMetricCompareMode: isMetricCompareModeSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  clearFundTrackerInitShareClassData: data => dispatch(addAppContext(data)),
  fundTrackerPreferenceChange: data => dispatch(fundTrackerPreferenceChange(data)),
  saveAppPreferences: data => dispatch(saveAppPreferences(data)),
  setIsPrintingInProgress: flag => dispatch(isPrintingInProgress(flag))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundTracker));
