import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaintenanceAlerts from '../UserDetails/Features/MaintenanceAlerts/MaintenanceAlerts';
import DummyCards from './DummyCards';
import CardError from './Card/CardError';
import { dashboardCardsError, getDashboardCardsPermissions } from '../../selectors';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const DashboardCards = ({ permissions, dashboardError }) => {
  const dashboardCardsConfig = useMemo(() => [
    {
      display: permissions.includes('MaintenanceAlerts'),
      Component: MaintenanceAlerts,
      moduleName: 'MaintenanceAlerts',
    },
    {
      display: permissions.includes('Users'),
      Component: DummyCards,
      moduleName: 'Users',
    },
    {
      display: permissions.includes('Organizations'),
      Component: DummyCards,
      moduleName: 'Organizations',
    },
    {
      display: permissions.includes('PendingApprovals'),
      Component: DummyCards,
      moduleName: 'Approvals',
    },
  ].filter(card => card.display), [permissions]);

  return (
    permissions.length ? (
      <div className='usersearch-dashboardCards'>
        {dashboardCardsConfig.map(({ Component, moduleName }) => (
          <Component key={moduleName} moduleName={moduleName} />
        ))}
      </div>
    ) : (
      dashboardError && <CardError errorMessage={t('tkDashboardCardError')} allModulesError />
    )
  );
};

const mapStateToProps = state => ({
  permissions: getDashboardCardsPermissions(state),
  dashboardError: dashboardCardsError(state),
});

DashboardCards.propTypes = {
  permissions: PropTypes.array,
  dashboardError: PropTypes.bool,
};

export default connect(mapStateToProps)(DashboardCards);
