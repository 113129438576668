import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {withRouter} from 'react-router-dom';
import {Checkbox} from '@gs-ux-uitoolkit-react/checkbox';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {
  benchmarkMetricsOptionsSelector, metricsOptionsSelector,
  selectedTabMetricsOptionSelector, thresholdsDeletedFlagSelector
} from '../../../selectors/pages/fundTracker';
import {
  lastShareclassIdsSelector, primaryMetricFromFundFinderOrPreferenceSelector,
  topOpeningBalanceShareclassIdsSelector
} from '../../../selectors/preferences';
import {
  deleteFundTrackerThresholds, loadFundTrackerCompareData
} from '../../../actions/page/fundTracker';
import {MODULES_KEYS} from '../../../constants/pageConstants';
import {FUND_TRACKER, REBATE_ELIGIBLE_METRICS, DATA_TYPE, ADJUSTED_PREFIX} from '../constants';
import Button from '../../../components/core/Button';
import ChartDropdown from '../components/ChartDropdown';
import ChartComponent from '../components/HighCharts';
import CompareComponent from '../components/CompareComponent';
import ThresholdModal from '../components/ThresholdModal';
import {saveAppPreferences} from '../../../actions/preferences';
import {DefaultChartConfig} from '../configs';
import {useAsync} from '../utils/hooks';
import {
  addFundsOrBenchmarkSeriesToChart, clearDataMissingMessages, getAllThresholdsForSelectedFunds,
  getNormalizedGridData, getSelectedShareClassIds, isAllThresholdsEmpty, setFieldForQuery,
  setMetricBenchmarksToState, verifyThresholds
} from '../utils';
import {addAppContext} from '../../../actions/app';
import {Conditional} from '../../../components/core/Conditional';
import {userGuidSelector} from '../../../selectors/user';
import CardComponent from '../components/CardComponent';
import CompareDataGrid from '../components/CompareDataGrid';
import ControlPanel from '../components/CompareDataGrid/ControlPanel';
import translator from '../../../services/translator';
import {shareClassFromFundFinderOrPreferenceSelector} from '../../../selectors/app';
import {pageIdSelector} from '../../../selectors/pages';
import {setChartRangeOptions, setIsApplyRebates, updateChartOptions} from '../store/fund-tracker-slice';
import {fundTrackerStateProps} from '../store/helpers';
import {
  fetchChartDataForSelectedProducts, getFundTrackerThresholds, saveFundTrackerThreshold, updateCompareMetricsToChart
} from '../services';
import './index.scss';
import {usePrevious} from '../../../utils/customHooks/usePrevious';

const { translate: t } = translator;

const FundTracker = (props) => {
  const {
    allMetricOptions, allShareClassData, benchmarkMetricsOptions, chartInstance, chartOptions,
    clearFundTrackerInitShareClassData, fieldForQuery, handleDeleteThreshold, lastShareclassIds,
    loadDataforMyTopHoldings, metrics: selectedMetrics = [], benchmarks: selectedFundsOrBenchmarks = [],
    primaryProduct, selection, shareClassFromFundFinderOrPreference, shareClassPerformanceData,
    shareclass, thresholdList = [], thresholdsDeletedFlag, topOpeningBalanceShareclassIds,
    isApplyRebatesFlagForInitFundLoad, isApplyRebates, selectedTabMetricsOptions, primaryMetricFromFundFinderOrPreference,
    isRebateExistsForPrimaryProduct, isRebateExistsForSomeFund, userGuid, pageId, isLoadingTopOpeningFunds
  } = props;
  const chartDispatch = useDispatch();
  const [showThresholdModal, setShowThresholdModal] = useState(false);
  const [thresholdConfig, setThresholdConfig] = useState({});
  const [primaryMetricItem = {}, compareMetricItem] = selectedMetrics;
  const isMetricsCompareMode = useMemo(() => (selection === FUND_TRACKER.METRICS), [selection]);
  const isBenchMarksCompareMode = useMemo(() => (selection === FUND_TRACKER.BENCHMARKS), [selection]);
  const { run } = useAsync(chartDispatch);

  const prevPrimaryProduct = usePrevious(primaryProduct);
  const hasPrimaryProductChanged = !!prevPrimaryProduct && !isEqual(
    {type: prevPrimaryProduct?.type, value: prevPrimaryProduct?.value},
    {type: primaryProduct?.type, value: primaryProduct?.value}
  );

  const defaultMetricItem = useMemo(() => {
    return selectedTabMetricsOptions.find((item) => {
      if (primaryMetricFromFundFinderOrPreference) {
        return (item.enum === primaryMetricFromFundFinderOrPreference);
      }
      return item.default;
    });
  }, [selectedTabMetricsOptions, primaryMetricFromFundFinderOrPreference]);

  const hasMetricForTabLoaded = useMemo(() => {
    return hasPrimaryProductChanged || (selectedTabMetricsOptions.length > 0 && defaultMetricItem);
  }, [defaultMetricItem, selectedTabMetricsOptions]);

  useEffect(() => {
    if (defaultMetricItem) {
      let metricItems = [defaultMetricItem];
      if (isMetricsCompareMode) {
        metricItems = [defaultMetricItem, compareMetricItem];
        if (selectedTabMetricsOptions.map(item => item.enum).includes(primaryMetricItem?.enum)) {
          metricItems[0] = primaryMetricItem;
        }
      }
      const updatedData = {
        metrics: metricItems
      };
      setMetricBenchmarksToState(chartDispatch, updatedData);
    }
  }, [defaultMetricItem, compareMetricItem, isMetricsCompareMode]);

  const loadChartData = (isApplyRebates, metricItem) => {
    if (isMetricsCompareMode) {
      updateCompareMetricsToChart(
        chartDispatch,
        chartOptions,
        chartInstance,
        {primaryProduct, isApplyRebates, allShareClassData},
        [metricItem, compareMetricItem]
      );
    } else if (primaryProduct?.value) {
      const { label: fieldLabel, value: fieldValue } = metricItem;
      fetchChartDataForSelectedProducts({
        run,
        chartDispatch,
        primaryProduct,
        shareClassField: { fieldLabel, fieldValue },
        selectedFundsOrBenchmarks,
        isApplyRebates
      });
    }
  };

  // ******************************************************************************
  //                  Below effect loads the data for the chart
  // ******************************************************************************
  useEffect(() => {
    const loadPrimaryFund = isLoadingTopOpeningFunds ? (pageId === FUND_TRACKER.PERFORMANCE) : true;
    if (selectedTabMetricsOptions.length > 0 && loadPrimaryFund && (typeof isApplyRebatesFlagForInitFundLoad !== 'undefined')) {
      setFieldForQuery(chartDispatch, defaultMetricItem);
      loadChartData(isApplyRebatesFlagForInitFundLoad, defaultMetricItem);
    }
  }, [hasMetricForTabLoaded]);

  useEffect(() => {
    if (hasPrimaryProductChanged) {
      loadChartData(isApplyRebates, primaryMetricItem);
    }
  }, [hasPrimaryProductChanged]);

  useEffect(() => {
    getFundTrackerThresholds(run, chartDispatch, userGuid);
  }, [chartDispatch, run, userGuid]);

  useEffect(() => {
    if (!isEmpty(allShareClassData)) {
      props.loadFundTrackerCompareData(getNormalizedGridData(shareClassPerformanceData));
    }
  }, [allShareClassData, shareClassPerformanceData]);

  useEffect(() => {
    if (loadDataforMyTopHoldings) {
      addFundsOrBenchmarkSeriesToChart(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        selectedFundsOrBenchmarks,
        chartDispatch,
        chartOptions,
        { chartInstance, allShareClassData, primaryProduct, isApplyRebates, benchmarks: selectedFundsOrBenchmarks },
        true,
        [{ value: primaryProduct.value, type: DATA_TYPE.FUND }, ...selectedFundsOrBenchmarks]
      );
    }
  }, [
    allShareClassData, benchmarkMetricsOptions, chartDispatch,
    chartInstance, chartOptions, fieldForQuery, isApplyRebates,
    loadDataforMyTopHoldings, primaryProduct, run, selectedFundsOrBenchmarks
  ]);

  const setChartRangeSelections = (selectedZoomOption, selectedChartDateRange) => {
    chartDispatch(
      setChartRangeOptions({
        selectedZoomOption,
        selectedChartDateRange
      })
    );
  };

  useEffect(() => {
    return () => {
      // Clear the shareclass data which was set when user navigated from Preference / Fund Finder screen
      clearFundTrackerInitShareClassData({
        fundTrackerInitialShareClassData: {
          primaryShareClassDetails: null,
          additionalShareClassIds: []
        }
      });

      // Clear the Fund/Benchmark data missing message
      clearDataMissingMessages(chartDispatch);

      // Reset the chart options
      chartDispatch(updateChartOptions(
        {chartOptions: {...DefaultChartConfig}}
      ));

      setChartRangeSelections(1, null);
    };
  }, []);

  const toggleShowThresholdsModal = useCallback(() => {
    const funds = selectedFundsOrBenchmarks.filter(({type}) => (type === DATA_TYPE.FUND));
    const [{value: metric} = {}] = selectedMetrics;
    const applyRebates = REBATE_ELIGIBLE_METRICS.includes(metric) && isApplyRebates;
    const {
      isAllThresholdHasSameLimits,
      isExistingThresholdItem,
      baseLowerThreshold,
      baseUpperThreshold,
      emailNotificationEnabled
    } = verifyThresholds(
      primaryProduct,
      funds,
      selectedMetrics,
      thresholdList,
      isBenchMarksCompareMode,
      applyRebates
    );
    const allThresholdsEmpty = isAllThresholdsEmpty(
      primaryProduct,
      isBenchMarksCompareMode,
      funds,
      selectedMetrics,
      thresholdList
    );
    // Set the values to the state
    setThresholdConfig({
      isAllThresholdHasSameLimits: isAllThresholdHasSameLimits || allThresholdsEmpty,
      isExistingThresholdItem,
      baseLowerThreshold,
      baseUpperThreshold,
      emailNotificationEnabled
    });
    setShowThresholdModal(!showThresholdModal);
  });

  const handleSetThreshold = (thresholdList) => {
    const [
      metric, lowerThreshold, upperThreshold, emailNotificationEnabled
    ] = thresholdList;
    const thresholds = getSelectedShareClassIds(primaryProduct, isBenchMarksCompareMode, selectedFundsOrBenchmarks)
      .map(shareClassId => {
        const shareClassData = allShareClassData.find(data => data.id === shareClassId);
        const metricItem = selectedTabMetricsOptions.find(item => (item.enum === metric));
        const isMetricRebateEligible = REBATE_ELIGIBLE_METRICS.includes(metricItem?.value);
        const isAdjustedMetric = shareClassData?.shareclassPerformance?.isRebateExists && isMetricRebateEligible && isApplyRebates;
        const thresholdMetric =  isAdjustedMetric ? `${ADJUSTED_PREFIX}${metric}` : metric;
        return {
          metric: thresholdMetric,
          shareClassId,
          lowerThreshold,
          upperThreshold,
          emailNotificationEnabled
        };
      });
    const thresholdPayload = {
      userGuid,
      thresholds
    };
    saveFundTrackerThreshold(run, chartDispatch, thresholdPayload);
    // Close the modal
    setShowThresholdModal(!showThresholdModal);
  };

  const thresholdModalProps = useMemo(() => ({
    title: t('tkSetThresholds'),
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: { overflow: 'hidden' },
    isModalOpen: showThresholdModal,
    isApplyRebates,
    additionalProps: {
      selectedMetric: primaryMetricItem.enum,
      metricOptions: allMetricOptions,
      isAllThresholdLimitsEqual: thresholdConfig.isAllThresholdHasSameLimits,
      isExistingThresholdItem: thresholdConfig.isExistingThresholdItem,
      lowerThreshold: thresholdConfig.baseLowerThreshold,
      upperThreshold: thresholdConfig.baseUpperThreshold,
      emailNotificationEnabled: thresholdConfig.emailNotificationEnabled,
      primaryProduct,
      isBenchMarksCompareMode,
      selectedFundsOrBenchmarks,
      userGuid,
      thresholdList: getAllThresholdsForSelectedFunds(
        primaryProduct,
        isBenchMarksCompareMode,
        selectedFundsOrBenchmarks,
        selectedMetrics,
        thresholdList,
        isApplyRebates
      ),
      thresholdsDeletedFlag
    },
    handleSetThreshold,
    handleDeleteThreshold,
    handleClose: toggleShowThresholdsModal
  }));

  const showCardComponent = () => {
    const isMetricsCompareMode = selection === FUND_TRACKER.METRICS;
    return !selection || isMetricsCompareMode || allShareClassData.length <= 1;
  };

  const disableSetThreshold = !primaryProduct?.value || isMetricsCompareMode;

  const isPreferenceOnlyHasTopHoldingFunds = useMemo(() => {
    const { primaryShareClassDetails } = shareClassFromFundFinderOrPreference || {};
    const isShareClassFromFundFinderOrPreference = !!primaryShareClassDetails;
    return (
      !isShareClassFromFundFinderOrPreference && !isEmpty(topOpeningBalanceShareclassIds) && isEmpty(lastShareclassIds)
    );
  }, [shareClassFromFundFinderOrPreference, topOpeningBalanceShareclassIds, lastShareclassIds]);

  const isApplyRebatesOptionVisible = useMemo(() => {
    const {fieldValue} = fieldForQuery || {};
    if (fieldValue && REBATE_ELIGIBLE_METRICS.includes(fieldValue)) {
      return isMetricsCompareMode ? isRebateExistsForPrimaryProduct : !!isRebateExistsForSomeFund;
    }
    return false;
  }, [fieldForQuery, isMetricsCompareMode, isRebateExistsForPrimaryProduct, isRebateExistsForSomeFund]);

  const onApplyRebates = async (event) => {
    const {checkedState: isApplyRebates} = event;
    // Update the preference
    props.saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        applyRebates: isApplyRebates
      }
    });
    chartDispatch(setIsApplyRebates(isApplyRebates));
    // Load the updated data
    loadChartData(isApplyRebates, primaryMetricItem);
  };

  return (
    <div className='chartsection'>
      <div className='chartsection__centerPane'>
        <div className='chartsection__chart'>
          <div className='chartsection__actionitems'>
            <div className='left-section'>
              <ChartDropdown
                {...props}
                isPreferenceOnlyHasTopHoldingFunds={isPreferenceOnlyHasTopHoldingFunds}
              />
            </div>
            <div className="right-section">
              <Conditional condition={isApplyRebatesOptionVisible}>
                <div className="apply-rebates">
                  <Checkbox
                    name="cbApplyRebates"
                    checked={isApplyRebates}
                    onChange={onApplyRebates}>
                    {t('tkApplyRebates')}
                  </Checkbox>
                </div>
              </Conditional>
              <div className="compare">
                <CompareComponent {...props} />
              </div>
              <div className='notification'>
                <Button
                  clickHandler={toggleShowThresholdsModal}
                  iconClass='button-icon'
                  extraClass='set-threshold-button'
                  isDisabled={disableSetThreshold}
                  label={t('tkSetThresholds')}
                />
              </div>
            </div>
          </div>
          <ChartComponent />
        </div>
      </div>
      {
        !!shareclass && (
          <div className='chartsection__data'>
            <div className='controlPanel'>
              <ControlPanel id={MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID} />
            </div>
            <Conditional condition={showCardComponent()}>
              <CardComponent
                shareclass={shareclass}
                shareClassPerformanceData={shareClassPerformanceData}
              />
              <CompareDataGrid {...props} />
            </Conditional>
          </div>
        )
      }
      <ThresholdModal {...thresholdModalProps} />
    </div>
  );
};

FundTracker.propTypes = {
  loadFundTrackerCompareData: PropTypes.func,
  selectedTabMetricsOptions: PropTypes.array,
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  userGuid: PropTypes.string,
  clearFundTrackerInitShareClassData: PropTypes.func,
  handleDeleteThreshold: PropTypes.func,
  thresholdsDeletedFlag: PropTypes.bool,
  saveAppPreferences: PropTypes.func,
  primaryMetricFromFundFinderOrPreference: PropTypes.string,
  shareClassFromFundFinderOrPreference: PropTypes.object,
  topOpeningBalanceShareclassIds: PropTypes.array,
  lastShareclassIds: PropTypes.array,
  shareClassPerformanceData: PropTypes.array,
  allShareClassData: PropTypes.array,
  shareclass: PropTypes.object,
  thresholdList: PropTypes.array,
  selection: PropTypes.string,
  fieldForQuery: PropTypes.string,
  metrics: PropTypes.array,
  benchmarks: PropTypes.array,
  chartOptions: PropTypes.object,
  chartInstance: PropTypes.object,
  loadDataforMyTopHoldings: PropTypes.bool,
  primaryProduct: PropTypes.string,
  isApplyRebatesFlagForInitFundLoad: PropTypes.bool,
  isApplyRebates: PropTypes.bool,
  isLoadingTopOpeningFunds: PropTypes.bool,
  isRebateExistsForPrimaryProduct: PropTypes.bool,
  isRebateExistsForSomeFund: PropTypes.bool,
  pageId: PropTypes.string
};

const mapStateToProps = state => ({
  selectedTabMetricsOptions: selectedTabMetricsOptionSelector(state),
  allMetricOptions: metricsOptionsSelector(state),
  benchmarkMetricsOptions: benchmarkMetricsOptionsSelector(state),
  userGuid: userGuidSelector(state),
  thresholdsDeletedFlag: thresholdsDeletedFlagSelector(state),
  primaryMetricFromFundFinderOrPreference: primaryMetricFromFundFinderOrPreferenceSelector(state),
  shareClassFromFundFinderOrPreference: shareClassFromFundFinderOrPreferenceSelector(state),
  topOpeningBalanceShareclassIds: topOpeningBalanceShareclassIdsSelector(state),
  lastShareclassIds: lastShareclassIdsSelector(state),
  pageId: pageIdSelector(state),
  ...fundTrackerStateProps(state)
});

const mapDispatchToProps = dispatch => ({
  loadFundTrackerCompareData: data => dispatch(loadFundTrackerCompareData(data)),
  clearFundTrackerInitShareClassData: data => dispatch(addAppContext(data)),
  handleDeleteThreshold: data => dispatch(deleteFundTrackerThresholds(data)),
  saveAppPreferences: data => dispatch(saveAppPreferences(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundTracker));
