import XLSX from 'xlsx-js-style';
import moment from 'moment/moment';

import { DATA_TYPE } from '../../constants';
import { composeTranslatedLabels } from '../../../../utils/stringUtils';

export const columnAttributes = {
  'NAV: Trading': 'navTrading',
  'Nav: Market Value': 'navMarketValue',
  'Daily Factor': 'dailyFactor',
  'Yield: 1-Day': 'yield1Day',
  'Yield: 7-Day Current': 'yield7DayCurrent',
  'Yield: 7-Day Effective': 'yield7DayEffective',
  'Yield: 30-Day': 'yield30Day',
  'Adjusted Yield: 1-Day': 'adjustedOneDayYield',
  'Adjusted Yield: 7-Day Current': 'adjustedSevenDayCurYield',
  'Adjusted Yield: 7-Day Effective': 'adjustedSevenDayEffYield',
  'Adjusted Yield: 30-Day': 'adjustedThirtyDayYield',
  'Liquid Assets: Daily (%)': 'dailyLiquidAssets',
  'Liquid Assets: Weekly (%)': 'weeklyLiquidAssets',
  'WAM': 'wam',
  'WAL': 'wal',
  'Total Fund Assets (MM)': 'fundAssets',
  'Net Shareholder Flows: Daily (%)': 'netShareholderFlowsDailyPercent',
  'Net Shareholder Flows: Daily (MM)': 'netShareholderFlowsDailyMm'
};

const getFormattedObject = (row, exportData, header, headerAttr) => {
  const { type } = exportData;
  const { FUND } = DATA_TYPE;
  return {
    Date: moment(row.perfDate).format('DD-MMM-YY'),
    'Fund Name': type === FUND ? exportData.shareclass.longName : exportData.benchmark.name,
    CUSIP: type === FUND ? exportData.shareclass.cusip || '' : '',
    ISIN: type === FUND ? exportData.shareclass.isin || '' : '',
    'BBG ID': type === FUND ? exportData.shareclass.bloombergTicker || '' : '',
    'Fund #': type === FUND ? exportData.shareclass.shareClassDetails.displayCode || '' : '',
    'NASDAQ Ticker': type === FUND ? exportData.shareclass.nasdaqTicker || '' : '',
    [header]: row[headerAttr] || ''
  };
};

export const getData = (excelExportData, selectedOption, header, headerAttr) => {
  const data = [];
  for (let j = 0; j < excelExportData.length; j++) {
    const metricDataSize = excelExportData[j].metrics.length;

    // if metrics empty, continue to next iteration
    if (metricDataSize > 0) {
      const lastElemIndex = metricDataSize - 1;
      const startDate = moment(excelExportData[j].metrics[lastElemIndex].perfDate).format('DD');
      excelExportData[j].metrics.forEach((row, index) => {
        if (selectedOption.label === 'Daily') {
          data.push(getFormattedObject(row, excelExportData[j], header, headerAttr));
        } else if (selectedOption.label === 'Weekly' && ((lastElemIndex - index) === 0 || (lastElemIndex - index) % 7 === 0)) {
          data.push(getFormattedObject(row, excelExportData[j], header, headerAttr));
        } else if (selectedOption.label === 'Monthly') {
          /* For monthly data frequency, in the given date range, if the start date present in the month, take data
       for the date and if it is not present, take date for the last date of the month  */
          const date = moment(row.perfDate).format('DD');
          if (startDate < '29') {
            if (date === startDate) {
              data.push(getFormattedObject(row, excelExportData[j], header, headerAttr));
            }
          } else {
            const month = moment(row.perfDate).format('MM');
            const lastDate = moment(month).endOf('month').format('DD');
            if (startDate < lastDate) {
              if (date === startDate) {
                data.push(getFormattedObject(row, excelExportData[j], header, headerAttr));
              }
            } else {
              if (date === lastDate) {
                data.push(getFormattedObject(row, excelExportData[j], header, headerAttr));
              }
            }
          }
        }
      });
    }
  }
  return data;
};

export const setSheetStyle = (data, worksheet, text) => {
  worksheet['!merges'] = [{s: {c: 0, r: 0}, e: {c: 1, r: 0}}, {s: {c: 0, r: data.length+3}, e: {c: 1, r: data.length+3}}, {s: {c: 0, r: data.length+5}, e: {c: 1, r: data.length+5}}];
  worksheet['!rows'] = [];
  worksheet['!rows'][data.length+5] = {hpt: text.length/5};
  const keys = Object.keys(worksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(worksheet[keys[w]]) === 'object') {
      const cell = XLSX.utils.decode_cell(keys[w]);
      if(cell.c === 0 && cell.r === 0) {
        worksheet[keys[w]].s = {font: {bold: true, sz: '14', name: 'Arial'}, fill : {bgColor: {rgb:'D0CECE'}, fgColor: {rgb: 'D0CECE'}}};
      }
      else if(cell.r === 1) {
        worksheet[keys[w]].s = {font : {bold: true, sz: '12', name: 'Arial'}};
      }
      else if( cell.r === data.length + 3 || cell.r === data.length + 5 ) {
        worksheet[keys[w]].s = {font : {bold: true, sz: '8.5', name: 'Arial'},  alignment : {wrapText: true, vertical: 'center'}};
      }
      else {
        worksheet[keys[w]].s = {font : {sz: '8.5', name: 'Arial'}};
      }
    }
  }
};

export const getWidth = (data = [], maxWidths, worksheet) => {
  const rowHeader = Object.keys(data.length > 0 ? data[0] : {});
  const headerWidths = rowHeader.map(r => r.length);
  const widths = rowHeader.map(row => (data.reduce((w, r) => Math.max(w, r[row].length), 20)));
  maxWidths = maxWidths.map((w, index) => (Math.max(Math.max(w, widths[index]), headerWidths[index])));
  worksheet['!cols'] = maxWidths.map(w => ({wch: w}));
  return maxWidths;
};

export const setCoverPageSheetStyle = (coverPageData, coverPageWorksheet, allShareClassData) => {
  const keys = Object.keys(coverPageWorksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(coverPageWorksheet[keys[w]]) === 'object') {
      const cell = XLSX.utils.decode_cell(keys[w]);
      if(cell.r === 0) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '48'}};
      }
      else if(cell.r === 1) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '20'}};
      }
      else if( cell.r === 6) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '18'}};
      }
      else if( (cell.r >= 9 && cell.r < 9 + allShareClassData.length) || cell.r === 10 + allShareClassData.length  ) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '16'}, alignment : {wrapText: '1'}};
      }
      else {
        coverPageWorksheet[keys[w]].s = {font : {sz: '12'}};
      }
      if(cell.r ===  coverPageData.length + 10 && cell.c === 1){
        coverPageWorksheet[keys[w]].s = {alignment : {horizontal: 'right'}};
      }
    }
  }
};

export const setDisclaimerStyle = (disclaimerWorksheet, disclaimerData) => {
  disclaimerWorksheet['!merges'] = [{s: {c:0, r: 0}, e: {c: 5, r: 0}}];
  disclaimerWorksheet['!rows'] = [{hpt: (disclaimerData[0].label.length)/5}];
  const keys = Object.keys(disclaimerWorksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(disclaimerWorksheet[keys[w]]) === 'object') {
        disclaimerWorksheet[keys[w]].s = {font : {bold: true, sz: '8.5', name: 'Arial'}, alignment : {wrapText: true, vertical: 'center'}};
    }
  }
};

export const sortFundTrackerExportData = (a, b, shareclassIds, benchmarkIds) => {
  let indexA;
  let indexB;

  if (a.type === 'fund' && b.type === 'fund') {
    indexA = shareclassIds.indexOf(a.id);
    indexB = shareclassIds.indexOf(b.id);
    // Handle case where ID might not be found
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;
    return indexA - indexB;
  } else if (a.type === 'benchmark' && b.type === 'benchmark') {
    indexA = benchmarkIds.indexOf(a.id);
    indexB = benchmarkIds.indexOf(b.id);
    // Handle case where ID might not be found
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;
    return indexA - indexB;
  } else if (a.type === 'fund') {
    return -1; // Keep funds before benchmarks if mixed types are compared
  } else {
    return 1; // Keep benchmarks after funds if mixed types are compared
  }
};

const buildExcelRow = (label = '', value = '') => ({ label, value });

export const getCoverPageData = ({ allShareClassData, headers }) => {
  const shareclasses = allShareClassData.filter(({ type }) => type !== DATA_TYPE.BENCHMARK);
  const selectFundsRows = shareclasses.map((i, index) =>
    index === 0
      ? buildExcelRow(`${composeTranslatedLabels(['tkSelectedFund(s)'])}:`, `${i.shareclass.longName}`)
      : buildExcelRow('', `${i.shareclass.longName}`)
  );

  const benchmarks = allShareClassData.filter(({ type }) => type === DATA_TYPE.BENCHMARK);
  const benchmarksRows = benchmarks.map((i, index) =>
    index === 0
      ? buildExcelRow(`${composeTranslatedLabels(['tkFundReferenceMetric(s)'])}:`, `${i.name}`)
      : buildExcelRow('', `${i.name}`)
  );

  const coverPageData = [
    buildExcelRow(composeTranslatedLabels(['tkReport', 'tkParameters'], { titleCase: true })),
    buildExcelRow(),
    buildExcelRow(),
    ...selectFundsRows,
    ...benchmarksRows,
    buildExcelRow(),
    buildExcelRow(composeTranslatedLabels(['tkSelectedMetric(s)']), `${headers.join(', ')}`)
  ];
  return coverPageData;
};
