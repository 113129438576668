import React, {useEffect, useCallback, useMemo, useRef, useState} from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import RadioGroup from '../../../../components/app/RadioGroup';
import { Conditional } from '../../../../components/core/Conditional';
import { FUND_TRACKER } from '../../constants';
import translator from '../../../../services/translator';
import { useAsync } from '../../utils/hooks';
import {
  diffArrays,
  updateChartSeries,
  setMetricBenchmarksToState,
  addFundsOrBenchmarkSeriesToChart,
  addMetricsSeriesToChart,
  cleanupGridData,
  updateChartOptions,
  updateShareclassPerformanceData,
  getRecentShareclassAndBenchmarkIds
} from '../../utils';
import SearchBenchmark from '../SearchBenchMarks';
import CompareModalHeader from './Header';
import SearchMetrics from '../SearchMetrics';
import { fundTrackerStateProps } from '../../store/helpers';
import { setSelection } from '../../store/fund-tracker-slice';
import './index.scss';

const { translate: t } = translator;

const customModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)'
};

const CompareModal = ({
  // component props
  allMetricOptions,
  benchmarkMetricsOptions,
  chartInstance,
  handleClose,
  isModalOpen,
  saveAppPreferences,
  // redux props
  allShareClassData,
  asOfDate,
  benchmarks,
  chartOptions,
  selection: compareSelection,
  fieldForQuery,
  primaryProduct,
  metrics: selectedMetrics,
  shareClassPerformanceData,
  isApplyRebates
}) => {
  const { BENCHMARKS, METRICS } = FUND_TRACKER;

  const chartDispatch = useDispatch();
  const compareModalRef = useRef();
  const { run } = useAsync(chartDispatch);
  const selectedFundOrBenchmarks = useRef([]);
  const selectedMetricItem = useRef({});
  const selection = useRef(BENCHMARKS);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [selectedCompareType, setSelectedCompareType] = useState(compareSelection || BENCHMARKS);
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  const radioOptions = useMemo(() =>
    [
      {
        value: BENCHMARKS,
        label: t('tkWithFundsAndMoneyNetAverages'),
        isSelected: selectedCompareType === BENCHMARKS
      },
      {
        value: METRICS,
        label: t('tkWithFundsOtherMetrics'),
        isSelected: selectedCompareType === METRICS
      }
    ]
  );

  useEffect(() => {
    setIsSaveEnabled(false);
  }, []);

  const handleChange = useCallback((action, data) => {
    if (action === BENCHMARKS) {
      selectedFundOrBenchmarks.current = data;
    } else if (action === METRICS) {
      selectedMetricItem.current = data;
    }
  }, []);

  const onClose = status => {
    setIsSaveEnabled(false);
    compareModalRef.current.closeModal(() => handleClose(status));
  };

  // ***************************************************************************************
  //              To get the metric item that needs to removed from the chart
  // ***************************************************************************************
  const getMetricsItemToRemove = selectedCompareMetricItem => {
    // If user has selected same compare metric item, which was already selected, no need to remove it.
    if (
      selectedCompareMetricItem &&
      compareMetricItem &&
      selectedCompareMetricItem.value === compareMetricItem.value
    ) {
      return undefined;
    }
    // return the existing metric item that needs to be removed, and newly selected compare metric item will set the state
    // down the line
    return compareMetricItem;
  };

  // ***************************************************************************************
  //            To remove Metrics and Funds/Benchmark series from chart
  // ***************************************************************************************
  const removeMetricsAndBenchmarksFromSeries = (selectedFundOrBenchmarks, metricsItemToRemove) => {
    const fundOrBenchmarksToRemove = diffArrays(
      benchmarks,
      selectedFundOrBenchmarks,
      (v1, v2) => v1.value === v2.value
    );
    return {
      fundOrBenchmarksToRemove,
      updatedChartOptions: updateChartSeries(
        metricsItemToRemove,
        fundOrBenchmarksToRemove,
        { chartOptions, chartInstance, primaryProduct },
        primaryMetricItem.value
      )
    };
  };

  // ***************************************************************************************
  //            To process added Funds/Benchmark data added from modal
  // ***************************************************************************************
  const processBenchmarkItems = () => {
    // Get the metric item to be removed from series
    const metricsItemToRemove = getMetricsItemToRemove();
    const selectedFundOrBenchmarksData = selectedFundOrBenchmarks.current || [];
    /* Remove the series from Chart for the benchmarks that were removed from Modal.
       We also need to remove if there were any compare metrics item that were set earlier */
    const { fundOrBenchmarksToRemove, updatedChartOptions } = removeMetricsAndBenchmarksFromSeries(
      selectedFundOrBenchmarksData,
      metricsItemToRemove
    );

    // Set it as user selected primary Share Class
    const { lastBenchmarkVendorIds, lastShareclassIds } = getRecentShareclassAndBenchmarkIds(
      selectedFundOrBenchmarksData,
      primaryProduct
    );
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        lastShareclassIds,
        lastBenchmarkVendorIds
      }
    });

    // Add/remove data from bottom grid
    cleanupGridData(
      selectedFundOrBenchmarksData,
      fundOrBenchmarksToRemove,
      allShareClassData,
      primaryProduct,
      chartDispatch
    );

    const newBenchmarkData = diffArrays(
      selectedFundOrBenchmarksData,
      benchmarks,
      (v1, v2) => v1.value === v2.value
    );

    // Get the newly added benchmarks to the chart series
    if (newBenchmarkData.length > 0) {
      addFundsOrBenchmarkSeriesToChart(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        newBenchmarkData,
        chartDispatch,
        updatedChartOptions,
        { chartInstance, allShareClassData, primaryProduct, isApplyRebates, benchmarks },
        true,
        [{ value: primaryProduct.value, type: primaryProduct.type }, ...selectedFundOrBenchmarksData]
      );
    } else if (fundOrBenchmarksToRemove && fundOrBenchmarksToRemove.length > 0) {
      const results = diffArrays(shareClassPerformanceData, fundOrBenchmarksToRemove, (v1, v2) => {
        return v1.id === v2.value;
      });
      batch(() => {
        updateChartOptions(chartDispatch, updatedChartOptions);
        updateShareclassPerformanceData(chartDispatch, { results, asOfDate });
      });
    }

    // Set the selected benchmark items to context
    const updatedData = {
      metrics: [primaryMetricItem],
      benchmarks: selectedFundOrBenchmarksData,
      selection: !!selectedFundOrBenchmarksData.length && BENCHMARKS
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  // ***************************************************************************************
  //                To process added Metrics data added from modal
  // ***************************************************************************************
  const processMetricItems = () => {
    // Get currently selected metric item
    const selectedCompareMetricItem = selectedMetricItem.current;
    // Get the metric item to be removed from series
    const metricsItemToRemove = getMetricsItemToRemove(selectedCompareMetricItem);
    // Capture the compare benchmarks and metrics items, which needs to be removed.
    const updatedChartOptions = updateChartSeries(
      metricsItemToRemove,
      benchmarks,
      { chartOptions, chartInstance, isApplyRebates, primaryProduct },
      primaryMetricItem.value
    );
    benchmarkMetricsOptions = [];
    // Add the newly selected metric item to the chart
    const selectedMetricItems = [primaryMetricItem, selectedCompareMetricItem];
    addMetricsSeriesToChart(
      selectedCompareMetricItem,
      run,
      chartDispatch,
      updatedChartOptions,
      { primaryProduct, isApplyRebates, allShareClassData },
      selectedMetricItems,
      false
    );
    // Add the selected compare metric item
    const metricsItemToAddToState = [...selectedMetrics.slice(0, 1), selectedCompareMetricItem];
    const updatedData = {
      benchmarks: [],
      metrics: metricsItemToAddToState,
      selection: selection.current
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  // ***************************************************************************************
  //       To handle the comparision of Multiple Metrics or Multiple Funds/Benchmarks
  // ***************************************************************************************
  const handleCompareBtnClick = status => {
    chartDispatch(setSelection(selection.current));
    if (selection.current === BENCHMARKS) {
      // If the benchmark option was selected in the modal
      processBenchmarkItems();
    } else if (selection.current === METRICS) {
      // If the Metrics option was selected in the modal
      processMetricItems();
    }
    setIsSaveEnabled(false);
    compareModalRef.current.closeModal(() => handleClose(status));
  };

  const onChange = () => {
    setIsSaveEnabled(true);
  };

  const handleCompareTypeChange = (_event, _isChecked, option) => {
    const compareType = option.id;
    setSelectedCompareType(compareType);
    selection.current = compareType;
    if ((compareType === METRICS) && !compareMetricItem) {
      setIsSaveEnabled(false);
    }
  };

  return (
    <Modal
      ref={compareModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass='modal--center compare-modal compare-modal__index'
      backdropCustomClass='compare-modal__backdrop-index'
      open={isModalOpen}
      handleClose={handleClose}
      animation={false}>
      <div className='compare-modal__innerBody'>
        <CompareModalHeader />
        <div className='compare-modal__inputContainer'>
          <div className='compare-modal__section'>
            <div className='margin-neg-8px'>
              <RadioGroup
                testId='compare-modal-radio-group'
                onChange={handleCompareTypeChange}
                options={radioOptions}
              />
            </div>
            <div className={'compare-modal__search'}>
              <Conditional condition={selectedCompareType === BENCHMARKS}>
                <SearchBenchmark
                  title={t('tkSearchFundsOrMoneyNetAverages')}
                  placeholder={`${t('tkSearch')}`}
                  onSelectCompareItem={handleChange}
                  onChange={onChange}
                />
                <SearchMetrics
                  title={t('tkSelectSecondaryMetric')}
                  allMetricOptions={allMetricOptions}
                  onSelectCompareItem={handleChange}
                  onChange={onChange}
                />
              </Conditional>
            </div>
          </div>
        </div>
        <div className='compare-modal__buttonRow'>
          <Button
            label={t('tkCancel')}
            customClass='button-secondary-small'
            clickHandler={event => onClose(event)}
          />
          <Button
            label={t('tkOk')}
            customClass='button-primary-small'
            isDisabled={!isSaveEnabled}
            clickHandler={event => handleCompareBtnClick(event)}
          />
        </div>
      </div>
    </Modal>
  );
};

CompareModal.propTypes = {
  // component props
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
  saveAppPreferences: PropTypes.func,
  // redux props
  allShareClassData: PropTypes.array,
  asOfDate: PropTypes.string,
  benchmarks: PropTypes.array,
  chartInstance: PropTypes.object,
  chartOptions: PropTypes.object,
  selection: PropTypes.string,
  fieldForQuery: PropTypes.string,
  primaryProduct: PropTypes.string,
  metrics: PropTypes.array,
  shareClassPerformanceData: PropTypes.array,
  isApplyRebates: PropTypes.bool
};

export default connect(fundTrackerStateProps)(CompareModal);
