import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

import { DefaultChartConfig } from '../configs';
import { FUND_TRACKER } from '../constants';
import { filterShareclassData, hasTopHoldingsShareclasses, removeRebateFunds } from '../utils';

const { BENCHMARKS } = FUND_TRACKER;

const asyncInitiated = [];

export const chartReducerInitialValue = {
  allShareClassData: [],
  asOfDate: moment(new Date()).format('YYYY-MM-DD'),
  benchmarks: [],
  chartInstance: undefined,
  chartOptions: { ...DefaultChartConfig },
  dropdown: {},
  excelExportData: [],
  loadDataforMyTopHoldings: false,
  loading: false,
  metrics: [],
  primaryProduct: {value: undefined, type: undefined},
  shareClassAndBenchmarkData: { shareClasses: [], benchmarks: [] },
  shareClassPerformanceData: [],
  shareclass: undefined,
  isFetchingChartData: false,
  snackbarMessages: []
};

// TODO: Slice can be improved. We made minimal change to preserve previous context reducers
const fundTrackerSlice = createSlice({
  name: 'fundTracker',
  initialState: chartReducerInitialValue,
  reducers: {
    registerChart: (state, { payload }) => {
      state.chartInstance = payload;
    },
    setShareClassAndBenchmarkData: (state, { payload }) => {
      state.shareClassAndBenchmarkData = payload;
    },
    clearAllFundTracker: state => {
      state.allShareClassData = [];
      state.benchmarks = [];
      state.primaryProduct = { value: undefined, type: undefined };
      state.shareclass = undefined;
      state.chartOptions = { ...DefaultChartConfig };
      state.shareClassPerformanceData = [];
      state.isFetchShareClsPerfDataInProgress = false;
    },
    updateInitialConfiguration: (state, { payload }) => {
      const { shareclassIdsFromPreference } = payload;
      const [primaryProduct, ...restOfShareclassIds] = shareclassIdsFromPreference;
      state.primaryProduct = primaryProduct;
      if (restOfShareclassIds && restOfShareclassIds.length > 0) {
        state.benchmarks = restOfShareclassIds;
        state.selection = BENCHMARKS;
      } else {
        state.benchmarks = [];
      }
    },
    updateFundTrackerPerformanceData: (state, { payload }) => {
      state.shareClassPerformanceData = [...state.shareClassPerformanceData, ...payload.results];
      state.asOfDate = payload.asOfDate;
    },
    setShareClassPerformanceData: (state, { payload }) => {
      state.shareClassPerformanceData = payload.results;
      state.asOfDate = payload.asOfDate;
    },
    // given a payload, reducer gets the prop name for the reducer and sets/updates its value in state
    updateChartState: (state, { payload }) => {
      const [propName] = Object.keys(payload);
      state[propName] = payload[propName];
    },
    isFetchShareClsPerfDataInProgress: (state, { payload }) => {
      state.isFetchShareClsPerfDataInProgress = payload;
    },
    setSelection: (state, { payload }) => {
      state.selection = payload;
    },
    // given an object with some attributes, reducer sets/updates the attributes in state
    setAttributes: (state, { payload }) => {
      const { type, value } = payload?.primaryProduct || {};
      const isTopHoldingPresentInState =
        hasTopHoldingsShareclasses(payload.benchmarks, state.benchmarks) && value === state.primaryProduct?.value;
      Object.assign(state, payload);
      if (payload.selection) {
        state.selection = payload.selection;
      }
      if (isTopHoldingPresentInState) {
        const { series, allShareClassData, shareClassPerformanceData } = filterShareclassData(state, [
          { value, type },
          ...payload.benchmarks
        ]);
        state.chartOptions = { ...state.chartOptions, series };
        state.allShareClassData = allShareClassData;
        state.shareClassPerformanceData = shareClassPerformanceData;
      } else {
        if (payload.isLoadingTopOpeningFunds) {
          state.loadDataforMyTopHoldings = true;
        }
      }
    },
    setDefaultState: (state, { payload }) => {
      Object.assign(state, chartReducerInitialValue, {
        chartInstance: payload.chartInstance,
        shareClassAndBenchmarkData: state.shareClassAndBenchmarkData,
        thresholdList: state.thresholdList
      });
    },
    /** dispatched when FT loads or tab is visited the first time */
    initialLoad: (state, { payload }) => {
      state.chartOptions = {
        ...state.chartOptions,
        series: payload.series,
        yAxis: [{ title: { text: payload.fieldLabel }, opposite: true, offset: 40 }]
      };
      const updatedSeries = removeRebateFunds(state.fieldForQuery?.fieldValue, state.chartOptions);
      if (updatedSeries) {
        state.chartOptions.series = updatedSeries;
      }
      state.primaryProduct = payload.primaryProduct;
      state.shareclass = { ...payload.shareClassDetails[0], points: [] };
      state.allShareClassData = payload.shareClassDetails;
      state.asOfDate = payload.asOfDate;
      state.shareClassPerformanceData = [];
      state.isLoadingTopOpeningFunds = false;
    },
    updateShareClassList: (state, { payload }) => {
      state.allShareClassData = payload.replaceShareClassData
        ? payload.allShareClassData
        : [...state.allShareClassData, ...payload.allShareClassData];
    },
    updateChartOptions: (state, { payload }) => {
      state.chartOptions = payload.chartOptions;
      state.loadDataforMyTopHoldings = false;
      const updatedSeries = removeRebateFunds(state.fieldForQuery?.fieldValue, state.chartOptions);
      if (updatedSeries) {
        state.chartOptions.series = updatedSeries;
      }
    },
    updateChartOptionsItem: (state, { payload }) => {
      state.chartOptions = { ...state.chartOptions, ...payload };
    },
    setFieldForQuery: (state, { payload }) => {
      state.fieldForQuery = payload.fieldForQuery;
    },
    setMetricBenchmarksToState: (state, { payload }) => {
      Object.assign(state, payload);
    },
    isFetchingChartData: (state, { payload }) => {
      state.isFetchingChartData = payload;
    },
    isMetricDataMissing: (state, { payload }) => {
      state.isMetricDataMissing = payload.isMetricDataMissing;
      state.isFundOrBenchmarkDataMissing = false;
      state.isFundOrBenchmarkSeriesDataMissing = false;
    },
    isFundOrBenchmarkDataMissing: (state, { payload }) => {
      state.isFundOrBenchmarkDataMissing = payload.isFundOrBenchmarkDataMissing;
      state.isMetricDataMissing = false;
      state.isFundOrBenchmarkSeriesDataMissing = false;
    },
    isFundOrBenchmarkSeriesDataMissing: (state, { payload }) => {
      state.isFundOrBenchmarkSeriesDataMissing = payload.isFundOrBenchmarkSeriesDataMissing;
      state.isMetricDataMissing = false;
      state.isFundOrBenchmarkDataMissing = false;
    },
    setThresholdList: (state, { payload }) => {
      state.thresholdList = payload.thresholdList;
    },
    setChartRangeOptions: (state, { payload }) => {
      const { selectedZoomOption, selectedChartDateRange } = payload;
      if (state.selectedZoomOption !== selectedZoomOption) {
        state.selectedZoomOption = selectedZoomOption;
      }
      if (state.selectedChartDateRange !== selectedChartDateRange) {
        state.selectedChartDateRange = selectedChartDateRange;
      }
    },
    setExcelExportData: (state, { payload }) => {
      // combine the funds and benchmarks export data into one.
      state.excelExportData = [...payload.fundsExport, ...payload.benchmarksExport];
    },
    isThresholdSaved: (state, { payload }) => {
      state.isThresholdSaved = payload;
    },
    toggleChartLoading: (state, { payload: showLoading }) => {
      state.loading = showLoading;
      if (showLoading) {
        state.chartInstance.showLoading();
      } else {
        state.chartInstance.hideLoading();
      }
    },
    pending: state => {
      if (asyncInitiated.length === 0 && state.chartInstance) {
        try {
          // state.chartInstance.showLoading();
        } catch (e) {
          console.log('error while showing loader on chart. Details:\n', e);
        }
      }
      if (state.chartInstance && !asyncInitiated.includes('pending')) {
        asyncInitiated.push('pending');
      }
      state.loading = true;
    },
    setErrorOrResolve: state => {
      if (state.chartInstance) {
        asyncInitiated.pop();
      }
      if (asyncInitiated.length === 0) {
        state.chartInstance && state.chartInstance.hideLoading();
      }
      state.loading = false;
    },
    setIsApplyRebates: (state, { payload }) => {
      state.applyRebates = payload;
    },
    setSnackbarMessages: (state, { payload }) => {
      const { override = true, messages } = payload;
      if (!override) {
        // append messages to existing messages
        state.snackbarMessages = [...state.snackbarMessages, ...messages];
      }
      // override existing messages by default
      state.snackbarMessages = payload.messages;
    }
  }
});

export const {
  initialLoad,
  isFetchShareClsPerfDataInProgress,
  isFundOrBenchmarkDataMissing,
  isFundOrBenchmarkSeriesDataMissing,
  isMetricDataMissing,
  isThresholdSaved,
  pending,
  registerChart,
  setChartRangeOptions,
  setDefaultState,
  setErrorOrResolve,
  setExcelExportData,
  setFieldForQuery,
  setAttributes,
  setMetricBenchmarksToState,
  setShareClassAndBenchmarkData,
  setShareClassPerformanceData,
  updateFundTrackerPerformanceData,
  setThresholdList,
  updateChartOptions,
  updateChartOptionsItem,
  updateChartState,
  updateInitialConfiguration,
  updateShareClassList,
  toggleChartLoading,
  clearAllFundTracker,
  setSelection,
  setIsApplyRebates,
  isFetchingChartData,
  setSnackbarMessages
} = fundTrackerSlice.actions;

export default fundTrackerSlice.reducer;
