import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import {createDeepEqualSelector} from '../../utils/selectorUtils';
import {shareClassFromFundFinderOrPreferenceSelector} from '../app';
import {DATA_TYPE, FUND_TRACKER} from '../../modules/FundTracker/constants';

const globalPreferences = {};
export const preferenceSelector = state => state.preferences;
export const globalPreferenceSelector = state => state.preferences && state.preferences.global || globalPreferences;
export const selectedFirmsSelector = state => state.preferences && state.preferences.global && state.preferences.global.selectedFirms || [];
export const accountIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.accountIdentifier || '';
export const fundIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.fundIdentifier || '';
export const selectedTradeTemplateId = state => state.preferences && state.preferences.global && state.preferences.global.selectedTradeTemplateId || '';
export const topOpeningBalanceShareclassIdsSelector = state => state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.topOpeningBalanceShareclassIds || [];
export const lastShareclassIdsSelector = state => state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.lastShareclassIds || [];
export const lastBenchmarkVendorIdsSelector = state => state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.lastBenchmarkVendorIds || [];
export const applyRebatesSelector = state => state.preferences && state.preferences.fundTracker && state.preferences.fundTracker.applyRebates || false;
export const fundTrackerContextSelector = state => state.modules.fundTracker && state.modules.fundTracker;

export const primaryProductSelector = createDeepEqualSelector(
  fundTrackerContextSelector,
  ({primaryProduct}) => primaryProduct
);

export const isBenchmarkCompareModeSelector = createDeepEqualSelector(
  fundTrackerContextSelector,
  ({selection}) => (selection === FUND_TRACKER.BENCHMARKS)
);

export const isMetricCompareModeSelector = createDeepEqualSelector(
  fundTrackerContextSelector,
  ({selection}) => (selection === FUND_TRACKER.METRICS)
);

export const primaryMetricFromFundFinderOrPreferenceSelector = createDeepEqualSelector(
  shareClassFromFundFinderOrPreferenceSelector,
  (shareClassDetails = {}) => {
    const { primaryShareClassDetails } = shareClassDetails;
    if (primaryShareClassDetails) {
      return primaryShareClassDetails.metric;
    }
    return '';
  }
);

export const preferredPrimaryFundsAndBenchmarks = createDeepEqualSelector(
  topOpeningBalanceShareclassIdsSelector,
  lastShareclassIdsSelector,
  lastBenchmarkVendorIdsSelector,
  shareClassFromFundFinderOrPreferenceSelector,
  primaryProductSelector,
  (
    topOpeningBalanceShareclassIds = [],
    lastShareclassIds = [],
    lastBenchmarkVendorIds = [],
    setInitialShareclassId,
    primaryProduct = {}
  ) => {
    const shareclassIds = [];
    const vendorIds = [];
    
    if(primaryProduct && primaryProduct.value && ![...lastShareclassIds, ...lastBenchmarkVendorIds].includes(primaryProduct.value)) {
      return [primaryProduct];
    }
    // when user navigates from `fundFinder` or `preference` screens.
    else if (!isEmpty(setInitialShareclassId) && !isEmpty(setInitialShareclassId.primaryShareClassDetails)) {
      const {
        primaryShareClassDetails: { shareClassId }
      } = setInitialShareclassId;
      return [{ type: DATA_TYPE.FUND, value: shareClassId }];
    } else if (!isEmpty(lastShareclassIds) || !isEmpty(lastBenchmarkVendorIds)) {
      // user had saved shareclasses in preferences
      if (!isEmpty(lastShareclassIds)) shareclassIds.push(...uniq(lastShareclassIds));
      if (!isEmpty(lastBenchmarkVendorIds)) vendorIds.push(...uniq(lastBenchmarkVendorIds));
    } else if (
      !isEmpty(topOpeningBalanceShareclassIds) &&
      (!primaryProduct.value || primaryProduct.value === topOpeningBalanceShareclassIds[0])
    ) {
      // top opening balance shareclasses
      const uniqueIds = uniq(topOpeningBalanceShareclassIds);
      uniqueIds.forEach(id => shareclassIds.push(id));
    }

    const preferredProductIds = [];
    if (primaryProduct.singleFundSelection) {
      preferredProductIds.push({
        type: primaryProduct.type,
        value: primaryProduct.value
      });
    } else if (primaryProduct.value) {
      // if primary product exists, this needs to remain first element
      preferredProductIds.push(primaryProduct);
      shareclassIds
        .filter(id => id !== primaryProduct.value)
        .forEach(shareclassId => preferredProductIds.push({
          type: DATA_TYPE.FUND,
          value: shareclassId
        }));
      vendorIds
        .filter(id => id !== primaryProduct.value)
        .forEach(id => preferredProductIds.push({
          type: DATA_TYPE.BENCHMARK,
          value: id
        }));
    } else {
      shareclassIds.forEach(shareclassId =>
        preferredProductIds.push({
          type: DATA_TYPE.FUND,
          value: shareclassId
        })
      );
      vendorIds.forEach(id =>
        preferredProductIds.push({
          type: DATA_TYPE.BENCHMARK,
          value: id
        })
      );
    }
    return preferredProductIds;
  }
);

export default {
  preferenceSelector
};
