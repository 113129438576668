import {createSelector} from 'reselect';
import groupByLodash from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import {firmsSelector, labelSelector, navBarSelector} from '../../app';
import Constants, {FILE_DOWNLOAD_ORIGIN, FILE_DOWNLOAD_TYPES} from '../../../constants/appConstants';
import {PORTFOLIO_FILTER_STATE, SORT} from '../../../constants/pageConstants';
import {
  allViewsSelector,
  columnsMetadata,
  filteredDataLength,
  filterVisibility,
  getFilteredDataLength,
  getFilterInErrorState,
  getFilters,
  gridData,
  gridError,
  groupBy,
  groupByFieldNew,
  groupColumns,
  isLoading
} from '../../../modules/Grid/selector';
import {createDeepEqualSelector} from '../../../utils/selectorUtils';
import {getCurrentGridViewData, getCurrentViewSelectorData, gridHeaderData} from '../../../helpers/gridFund';
import errorTypes from '../../../error/errorType';
import {amountFormatter, DECIMAL_FORMAT} from '../../../utils/amountFormatter';
import {getFileDownloadSignaturesSelector} from '../../app/reports';
import translator from '../../../services/translator';
import appRoutes from '../../../routes/appRoutes';
import {pageIdSelector} from '../index';

const {translate: t} = translator;

export const isPrintingInProgressSelector = state => state.pageData.isPrintingInProgress;
export const metricsOptionsSelector = state => (state.pageData.metricsOptions || []);
export const benchmarkMetricsOptionsSelector = state => (state.pageData.benchmarkMetricsOptions || []);
export const fundTrackerIframeLinkSelector = state => (state.pageData.fundTrackerIframeLink || '');
export const thresholdsDeletedFlagSelector = state => (state.pageData.thresholdsDeleted || false);
export const fundTrackerFieldSelector = (state, propName) => state.modules.fundTracker && state.modules.fundTracker[propName];
export const fundTrackerContextSelector = state => state.modules.fundTracker && state.modules.fundTracker;

export const isRebateExistsForPrimaryProductSelector = createSelector(
  fundTrackerContextSelector,
  (fundTracker) => {
    const {primaryProduct, allShareClassData} = fundTracker;
    return allShareClassData
      .filter(({id}) => (id === primaryProduct?.value))
      .some(item => item?.shareclassPerformance?.isRebateExists);
  }
);

export const isRebateExistsForSomeFundSelector = createSelector(
  fundTrackerContextSelector,
  (fundTracker) => {
    const {primaryProduct, allShareClassData = [], benchmarks = []} = fundTracker;
    const compareShareClassIds = benchmarks.map(({value}) => value);
    const shareClassIds = [primaryProduct?.value, ...compareShareClassIds];
    const shareClassData = allShareClassData.filter(({id}) => shareClassIds.includes(id));
    return shareClassData.some(item => item?.shareclassPerformance?.isRebateExists);
  }
);

export const isApplyRebatesFlagForInitFundLoadSelector = createSelector(
  fundTrackerContextSelector,
  (fundTracker) => {
    const {applyRebates: applyRebatesUserPreference} = fundTracker;
    if (typeof applyRebatesUserPreference !== 'undefined') {
      return (applyRebatesUserPreference);
    }
  }
);

export const isApplyRebatesSelector = createSelector(
  fundTrackerContextSelector,
  isRebateExistsForSomeFundSelector,
  (fundTracker, isRebateExistsForSomeFund) => {
    const {applyRebates: applyRebatesUserPreference} = fundTracker;
    return (applyRebatesUserPreference && isRebateExistsForSomeFund);
  }
);

export const selectedTabMetricsOptionSelector = createSelector(
  metricsOptionsSelector,
  pageIdSelector,
  (metricsOptions, pageId) => {
    const matchedItem = metricsOptions.filter((item) => item.value === pageId)[0];
    return matchedItem && matchedItem.items || [];
  }
);

export const getFundTrackerTabsConfig = createSelector(
  labelSelector,
  navBarSelector,
  (labels, navBar = {}) => {
    const {navRootConfig = []} = navBar;
    const fundTrackerNavConfigs = navRootConfig.find(({routingPathId}) =>
      routingPathId === Constants.FUND_TRACKER
    );
    const fundTrackerRoutes = appRoutes.find(({id}) => id === Constants.FUND_TRACKER).subRoutes;
    return fundTrackerNavConfigs && fundTrackerNavConfigs.subMenu
      .filter(({displayNameKey}) => displayNameKey)
      .map(data => {
        const matchedRoute = fundTrackerRoutes.find(item => item.id === data.routingPathId) || '';
        const {id, exact, path} = matchedRoute;
        return {id, exact, path, label: labels[data.displayNameKey]};
      }) || [];
  }
);

export const getFundTrackerNavLinks = createDeepEqualSelector(
  navBarSelector,
  (navBar = {}) => {
    const {navRootConfig = []} = navBar;
    const fundTrackerNavLinks = navRootConfig.find(data => (
      data.routingPathId === Constants.FUND_TRACKER
    ));
    return (fundTrackerNavLinks && fundTrackerNavLinks.subMenu);
  }
);

// ***********************************************************************************
//                             GRID related selectors
// ***********************************************************************************
const defaultCurrentView = {};
const defaultErrorObject = {};
export const getErrorObject = state => (
  state.pageContext.errorObject || defaultErrorObject
);
export const selectCollapseState = ({preferences = {}}) => (
  preferences.currentTradeStatus ? preferences.currentTradeStatus.isCollapsedAll : false
);
export const getSelectedFileType = (state, id) => (
  state.modules[id].transactionsFileDownloadType || state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.downloadFileType || FILE_DOWNLOAD_TYPES.EXCEL
);
export const exportExcel = state => (
  state.modules.currentTradeStatus.exportExcel || {}
);

export const groupByField = createSelector(groupBy, groupByField => groupByField === '' ? 'none' : groupByField || 'none');
export const getDataInlineError = ({modules}, id) => modules[id].dataError;

export const dropdownViewNamesSelector = createSelector(
  allViewsSelector,
  allViewsSelector => allViewsSelector.map(view => ({label: view.name, value: view.id, selected: view.isActive}))
);

// Get Active view
export const currentView = createSelector(
  allViewsSelector,
  (allViews) => {
    const view = allViews.find(view => view.isActive);
    return !view ? defaultCurrentView : view;
  }
);

export const groupByColumns = createSelector(
  groupColumns, labelSelector, groupByFieldNew,
  (groupByColumns, labels, groupByField) => (
    groupByColumns && groupByColumns.map(item => ({
      value: item.value,
      label: labels[item.label].toUpperCase(),
      selected: groupByField === item.value,
      nodeId: item.value
    }))
  )
);

export const selectedGroup = createSelector(
  groupByField,
  groupByColumns,
  (selectedGroupValue, groupByColumns) => {
    const selectedGroupLabel = groupByColumns && groupByColumns.length
      && groupByColumns.filter(group => group.value === selectedGroupValue)[0].label;
    return {
      value: selectedGroupValue,
      label: selectedGroupLabel
    };
  }
);

export const currentViewSelector = createSelector(
  currentView,
  currentView => getCurrentViewSelectorData({currentView, canTrade: false, isTradingDisabled: true})
);

export const getCurrentGridView = createSelector(
  currentViewSelector,
  columnsMetadata,
  groupByColumns,
  labelSelector,
  isRebateExistsForSomeFundSelector,
  (
    viewColumns,
    metadata = [],
    groupByColumns = [],
    labels,
    isRebateExistsForSomeFund
  ) => {
    const filteredMetaData = metadata.filter(({isAdjYieldColumn}) =>
      isRebateExistsForSomeFund ? true : !isAdjYieldColumn
    );
    return getCurrentGridViewData({
      viewColumns,
      metadata: filteredMetaData,
      groupByColumns,
      labels
    });
  }
);

export const preDefinedSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) =>
    currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC)) || []
  );

// Grid Columns
export const gridHeader = createSelector(
  getCurrentGridView,
  groupByFieldNew,
  preDefinedSortingState,
  columnsMetadata,
  (
    headerData = [],
    groupByField,
    sortedColums,
    metadata
  ) => {
    const headerData1= gridHeaderData({
      headerData,
      groupByField,
      sortedColums,
      metadata
    });
    return headerData1;
  }
);

export const getGridData = createSelector(
  gridData,
  groupBy,
  labelSelector,
  (gridData, groupBy, labels) => {
    return gridData.map(rowData => {
      const groupByArray = groupBy.split('And');
      let tradeShares = '';
      if (rowData && rowData.tradeShares) {
        tradeShares = amountFormatter(rowData.tradeShares, DECIMAL_FORMAT.THREE);
      }
      for (let i = 0; i < groupByArray.length; i++) {
        if (!rowData[groupBy] && rowData[groupBy] !== 0) {
          return {...rowData, [groupBy]: labels.tkCopyPort10, tradeShares};
        }
      }
      return {...rowData, tradeShares};
    }) || [];
  }
);

export const disableExcelExport = createSelector(
  gridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  (data, filteredData, filterVisibility, filters = [], filterInErrorState) => {
    if (filterInErrorState) {
      return true;
    } else if (filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN) {
      return !data.length;
    } else {
      return filters.length ? !filteredData : !data.length;
    }
  }
);

export const getCurrentViewsKeys = createSelector(
  getCurrentGridView,
  columns => {
    const result = {};
    columns.forEach(col => {
      result[col.colId] = ' ';
    });
    return result;
  }
);

export const snackbarListSelector = createSelector(
  labelSelector,
  getErrorObject,
  (labels, errorObject) => {
    const {errorCode, errorMessage} = errorObject;
    const list = [];
    if (errorCode === errorTypes.UNKNOWN_SERVICE_ERROR || errorCode === errorTypes.BUSINESS_FAILURE) {
      list.push({
        displayMultiple: true,
        type: 'error',
        msgCopy: labels[errorMessage],
        id: Date.now(),
      });
    }
    return list;
  });

export const noRowDataSelector = createSelector(
  getGridData, gridError, isLoading, labelSelector, firmsSelector, filteredDataLength,
  (data, error, loading, labels, firms = [], filteredData) => {
    if (filteredData === 0) {
      return labels.tkCopyPort04;
    } else if ((loading === undefined) || (data && data.length)) {
      return undefined;
    } else if (error && error.code === errorTypes.TOO_MUCH_DATA) {
      return labels.tkNoResultsFound;
    } else if (!firms.length) {
      return labels.tkNoCtsDataMsg;
    }
    return (loading || !(data && data.length)) ? '' : labels.tkNoCtsDataMsg;
  }
);

const getFileTypeOptions = () => ([
    {
      id: FILE_DOWNLOAD_TYPES.EXCEL,
      label: t('tkXLS'),
      value: FILE_DOWNLOAD_TYPES.EXCEL,
      isSelected: true
    },
    {
      id: FILE_DOWNLOAD_TYPES.PDF,
      label: t('tkPDF'),
      value: FILE_DOWNLOAD_TYPES.PDF
    }]
);

export const getFileTypes = createSelector(getSelectedFileType, getFileTypeOptions,
  (selectedFile, options) => {
    return options.map((option) => {
      return {
        value: option.id,
        label: option.label,
        isSelected: selectedFile === option.id
      };
    });
  });

export const disableFileDownload = createSelector(
  gridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  getFileDownloadSignaturesSelector,
  (data, filteredData, filterVisibility, filters = [], filterInErrorState, files) => {

    const transactionsFiles = files && files.filter(file => {
      return file.origin === FILE_DOWNLOAD_ORIGIN.TRANSACTIONS;
    }) || [];

    if (filterInErrorState) {
      return true;
    } else if (filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN) {
      return !data.length || transactionsFiles.length > 0;
    } else {
      return (filters.length ? !filteredData : !data.length) || transactionsFiles.length > 0;
    }
  }
);

const getLowerAndHigherMetricCurrentValue = (items) => {
  if (items.length > 1) {
    const values = items.map(item => item.metricValue);
    return [Math.min(...values), Math.max(...values)];
  }
  return [items[0].metricValue];
};

const getMetricLabelAndValueType = (metricOptions, metricEnum) => {
  let metricLabelAndType = [];
  if (metricEnum) {
    metricOptions.forEach(option => {
      const metricItem = option.items.find(item => (item.enum === metricEnum));
      if (metricItem) {
        metricLabelAndType = [metricItem.label, metricItem.type];
      }
    });
  }
  return metricLabelAndType;
};

export const fundTrackerThresholds = state => (state.pageData.fundTrackerThresholds || []);

const groupByMetricAndShareClass = (fundTrackerThresholds, thresholdFlatList, metricsOptions) => {
  const groupedValues = groupByLodash(fundTrackerThresholds, thresholdItem => {
    return `${thresholdItem.metric}-${thresholdItem.lowerThreshold}-${thresholdItem.upperThreshold}`;
  });

  // Flatten out
  Object.entries(groupedValues)
    .forEach(([parentKey, items = []]) => {
      const isAllChildItemsChecked = items.every(item => item.emailNotificationEnabled);
      const isAllChildItemsUnchecked = items.every(item => !item.emailNotificationEnabled);
      const [lowerMetricValue, upperMetricValue] = getLowerAndHigherMetricCurrentValue(items);
      const [metricLabel, metricValueType = ''] = getMetricLabelAndValueType(metricsOptions, items[0].metric);

      thresholdFlatList.push({
        value: '',
        label: parentKey,
        isIntermediateChecked: !(isAllChildItemsChecked || isAllChildItemsUnchecked),
        selected: isAllChildItemsChecked,
        nodeId: parentKey,
        metricLabel,
        metricValueType,
        lowerMetricValue,
        upperMetricValue,
        open: false,
        ...items[0]
      });
      if (items && items.length > 1) {
        items && items.forEach((thresholdItem) => {
          let metricBoundValue;
          if (thresholdItem.lowerThreshold && thresholdItem.upperThreshold) {
            metricBoundValue = `${thresholdItem.lowerThreshold}-${thresholdItem.upperThreshold}`;
          } else if (thresholdItem.lowerThreshold) {
            metricBoundValue = thresholdItem.lowerThreshold;
          } else if (thresholdItem.upperThreshold) {
            metricBoundValue = thresholdItem.upperThreshold;
          }
          thresholdFlatList.push({
            value: '',
            label: thresholdItem.metric,
            disabled: false,
            selected: thresholdItem.emailNotificationEnabled,
            open: true,
            parentId: parentKey,
            metricValueType,
            nodeId: `${thresholdItem.metric}-${thresholdItem.shareClassId}-${thresholdItem.shareClassId}-${metricBoundValue}`,
            ...thresholdItem
          });
        });
      }
    });
};

export const fundTrackerThresholdsSelector = createSelector(
  fundTrackerThresholds,
  metricsOptionsSelector,
  (fundTrackerThresholds, metricsOptions) => {
    const thresholdFlatList = [];
    if (!isEmpty(fundTrackerThresholds)) {
      groupByMetricAndShareClass(
        fundTrackerThresholds,
        thresholdFlatList,
        metricsOptions
      );
    }
    return thresholdFlatList;
  }
);
